import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import * as React from "react";
import {selectUrlId} from "../../selectors/other";
import {State} from "../../store/rootReducers";
import {getDetailedDocuments, getUnrecognizedPackagesImages, setDetailedDocumentLeftId} from "../../ducks/montitoring";
import {UrlLoader, UrlLoaderDispatchProps, UrlLoaderStateProps} from "./UrlLoader";

const mapStateToProps: MapStateToProps<UrlLoaderStateProps, {}, State> = state => {
    const urlId = selectUrlId(state);
    const urlParams = new URLSearchParams(window.location.search);
    const packageId = urlParams.get('packageId');

    return {
        urlId: packageId ? packageId : urlId,
    };
};

const dispatchProps: MapDispatchToProps<UrlLoaderDispatchProps, {}> = {
    onRequestById: (id: number | string) => {
        const urlParams = new URLSearchParams(window.location.search);
        const packageId = urlParams.get('packageId');
        return packageId ? getUnrecognizedPackagesImages(parseInt(packageId)) : getDetailedDocuments(id as string);
    },
    setItemId: (id: number | string) => {
        const urlParams = new URLSearchParams(window.location.search);
        const packageId = urlParams.get('packageId');
        return setDetailedDocumentLeftId(packageId ? undefined : id as string);
    }
};

export const DocumentDifferenceLoaderConnected = connect(
    mapStateToProps,
    dispatchProps
)(UrlLoader);
