import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {State} from "../../store/rootReducers";
import {DifferencePage, IDifferencePageDispatchProps, IDifferencePageStateProps} from "./DifferencePage";
import {
    selectComments,
    selectDetailedDiff,
    selectDetailedDocIdLeft,
    selectDetailedDocuments,
    selectIsTrackDisabled,
    selectLeftImages,
    selectLeftScale,
    selectRightImages,
    selectRightScale
} from "../../selectors/monitoring";
import {
    deleteUnrecognizedPackagesImages,
    MonitoringStatuses,
    onClearDifferencePage,
    PackageStatuses,
    setDetailedDiff,
    setDifferenceStatus,
    setIsTrackDisabled,
    setLeftScale,
    setRightScale,
    updateDocumentStatus
} from "../../ducks/montitoring";
import {popupPush} from "../../ducks/popup";
import {routeTo} from "../../ducks/baseCRUD";
import {sendTechnicalReport, updateTechReportText} from "../../ducks/upload";
import {selectTechReportText} from "../../selectors/upload";

const mapStateToProps: MapStateToProps<IDifferencePageStateProps, {}, State> = state => {
    const detailedDiff = selectDetailedDiff(state);
    const comments = selectComments(state);
    const documents = selectDetailedDocuments(state);
    let leftId = '';
    let rightId = '';
    let title = '';
    let comment = '';
    let documentRight = undefined;
    let documentLeft = undefined;
    let reportPackageId = 0;
    const leftDetailedDocumentId = selectDetailedDocIdLeft(state);
    const leftScale = selectLeftScale(state)
    const rightScale = selectRightScale(state)
    let docStatus: MonitoringStatuses | undefined = undefined;
    const reportText = selectTechReportText(state);
    const urlParams = new URLSearchParams(window.location.search);
    const packageId = urlParams.get('packageId');

    if (documents.length) {
        if ((documents[0] && documents[0].docflowDocumentData && documents[0].docflowDocumentData.statusId)
            || (documents[1] && documents[1].docflowDocumentData && documents[1].docflowDocumentData.statusId)
        ) {
            if ((documents[0] && documents[0].docflowDocumentData && documents[0].docflowDocumentData.statusId)
                && (documents[1] && documents[1].docflowDocumentData && documents[1].docflowDocumentData.statusId)) {
                docStatus = documents[0].docflowDocumentData.statusId;
                comment = documents[0].docflowDocumentData.comment;
                reportPackageId = documents[0].packageId;
            } else if ((documents[0] && documents[0].docflowDocumentData && documents[0].docflowDocumentData.statusId)) {
                docStatus = documents[0].docflowDocumentData.statusId;
                comment = documents[0].docflowDocumentData.comment;
                reportPackageId = documents[0].packageId;
            } else if ((documents[1] && documents[1].docflowDocumentData && documents[1].docflowDocumentData.statusId)) {
                docStatus = documents[1].docflowDocumentData.statusId;
                comment = documents[1].docflowDocumentData.comment;
                reportPackageId = documents[1].packageId;
            }
            switch (docStatus) {
                case MonitoringStatuses.ACTS_HAVE_DIFF:
                    title = 'ОБНАРУЖЕНЫ РАСХОЖДЕНИЯ';
                    break;
                case MonitoringStatuses.WAITING_FOR_PAIR:
                    title = 'ОЖИДАНИЕ ОТВЕТНОГО АКТА';
                    break;
                case MonitoringStatuses.DELETED:
                    title = 'АКТ УДАЛЕН';
                    break;
                case MonitoringStatuses.ACTS_MATCH:
                    title = 'АКТЫ СОПОСТАВЛЕНЫ';
                    break;
                case MonitoringStatuses.ACTS_PASSED:
                    title = 'АКТ ПРИНЯТ';
                    break;
                case MonitoringStatuses.ACTS_REJECTED:
                    title = 'АКТ ОТКЛОНЕН';
                    break;
            }
        } else if (
            (documents[0] && documents[0].packageStatusId === PackageStatuses.RECOGNIZED && documents[0].docId === null)
            || (documents[1] && documents[1].packageStatusId === PackageStatuses.RECOGNIZED && documents[1].docId === null)
        ) {
            title = 'Документ не распознан';
            docStatus = MonitoringStatuses.ACTS_NOT_RECOGNISED;
            reportPackageId = documents[0] ? documents[0].packageId : documents[1] ? documents[1].packageId : 0;
        } else if (
            (documents[0] && documents[0].packageStatusId === PackageStatuses.RECOGNIZED && documents[0].docId === null)
            || (documents[1] && documents[1].packageStatusId === PackageStatuses.RECOGNIZED && documents[1].docId === null)
        ) {
            title = 'Документ не распознан';
            docStatus = MonitoringStatuses.ACTS_NOT_RECOGNISED;
            reportPackageId = documents[0] ? documents[0].packageId : documents[1] ? documents[1].packageId : 0;
        }
    } else if (leftDetailedDocumentId === 'null' || leftDetailedDocumentId === undefined) {
        title = 'Документ не распознан';
        docStatus = MonitoringStatuses.ACTS_NOT_RECOGNISED;
    }

    if (documents.length === 2) {
        leftId = documents[0].docId;
        rightId = documents[1].docId;

        if (documents[0] && documents[0].docflowDocumentData && documents[0].docflowDocumentData.extendData && documents[0].docflowDocumentData.extendData.discrepancyData && documents[0].docflowDocumentData.extendData.discrepancyData.length > 0) {
            documentLeft = documents[0];
        }
        if (documents[1] && documents[1].docflowDocumentData && documents[1].docflowDocumentData.extendData && documents[1].docflowDocumentData.extendData.discrepancyData && documents[1].docflowDocumentData.extendData.discrepancyData.length > 0) {
            documentRight = documents[1];
        }
    }

    return {
        comment,
        docStatus,
        title,
        detailedDiff,
        packageId,
        detailedDocLeft: documentLeft,
        detailedDocRight: documentRight,
        comments,
        detailedDocLeftId: leftId,
        detailedDocRightId: rightId,
        imagesLeft: selectLeftImages(state),
        imagesRight: selectRightImages(state),
        leftScale,
        rightScale,
        isTrackDisabled: selectIsTrackDisabled(state),
        report: reportText,
        reportPackageId: [reportPackageId ? reportPackageId : parseInt(packageId)],
    }
};

const dispatchProps: MapDispatchToProps<IDifferencePageDispatchProps, {}> = {
    setDetailedDiff,
    popupPush,
    updateDocumentStatus,
    routeTo: (route, id?) => {
        return routeTo(route, id)
    },
    setDifferenceStatus,
    onClearDifferencePage,
    setLeftScale,
    setRightScale,
    setIsTrackDisabled,
    deleteUnrecognizedPackagesImages,
    sendTechnicalReport,
    updateTechReportText,
};

export const DifferencePageConnected = connect(
    mapStateToProps,
    dispatchProps,
)(DifferencePage);
