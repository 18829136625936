import React, {MouseEvent, useCallback, useEffect} from "react";

import styles from './PreviewModal.module.scss';
import {Button, CircularProgress} from "@mui/material";
import {IDocumentImage} from "../../api/monitoringApi";

export interface IPreviewModalStateProps {
    modalOpen: boolean;
    imagesLoading: boolean;
    images: IDocumentImage[];
    docIds: string[];
    docId: string;
}

export interface IPreviewModalDispatchProps {
    setPreviewModalOpen: (flag: boolean) => void;
    onClearDifferencePage: () => void;
    handleCreateDocsPair: (docIds: string[]) => void;
}

export type PreviewModalProps = IPreviewModalStateProps & IPreviewModalDispatchProps;


export function PreviewModal(props: PreviewModalProps) {
    const {
        modalOpen,
        imagesLoading,
        images,
        setPreviewModalOpen,
        onClearDifferencePage,
        docIds,
        docId,
        handleCreateDocsPair
    } = props;

    useEffect(() => {
        if (!modalOpen) {
            onClearDifferencePage();
        }
    }, [modalOpen])

    const closeModal = useCallback(() => {
        setPreviewModalOpen(false);
    }, []);

    const onButtonClick = () => {
        const ids = docIds;

        if (docIds.length === 1) {
            ids.push(docId);
        }
        handleCreateDocsPair(ids);
    }

    const onStopPropagation = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const buttonDisabled = ((docIds.length === 2 && !docIds.includes(docId)) || docIds.length === 1 && docIds.includes(docId) || (docIds.length === 0));

    return modalOpen ? <div className={styles.popupBackground} onClick={closeModal}>
        <div onClick={onStopPropagation} className={styles.modalContainer}>
            <div className={styles.closeIcon} onClick={closeModal}/>
            <div className={styles.photoListContainer}>
                {imagesLoading && <div className={styles.loading}>
                    <CircularProgress/>
                </div>}
                {!imagesLoading && images.map(image => {
                    return <div className='documentImageContainer'><img
                        className={styles.documentImage}
                        src={image.fileUrl}
                        alt={'Изображение документа'}
                    /></div>
                })}
            </div>
            <Button
                onClick={onButtonClick}
                disabled={buttonDisabled}
                className={`${styles.buttonAction} ${buttonDisabled ? styles.disabled : ""}`}
            >
                Сопоставить
            </Button>
        </div>
    </div> : <></>
}
