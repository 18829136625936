import {State} from "../store/rootReducers";

export function selectMonitoringList(state: State) {
    return state.monitoring.list;
}

export function selectDetailedDiff(state: State) {
    return state.monitoring.selectedDiff;
}

export function selectComments(state: State) {
    return state.monitoring.comments;
}

export function selectCommentInputShown(state: State) {
    return state.monitoring.isCommentInputShown;
}

export function selectRightImages(state: State) {
    return state.monitoring.rightDetailedImages;
}

export function selectLeftImages(state: State) {
    return state.monitoring.leftDetailedImages;
}

export function selectDetailedDocuments(state: State) {
    return state.monitoring.detailedDocuments;
}

export function selectLeftScale(state: State) {
    return state.monitoring.leftScale;
}

export function selectRightScale(state: State) {
    return state.monitoring.rightScale;
}

export function selectIsTrackDisabled(state: State) {
    return state.monitoring.isTrackDisabled;
}

export function selectDetailedDocIdLeft(state: State) {
    return state.monitoring.detailedDocumentIdLeft;
}

export function selectDetailedDocIdRight(state: State) {
    return state.monitoring.detailedDocumentIdRight;
}

export function selectSearchOptions(state: State) {
    return state.monitoring.searchOptions;
}

export function selectListPage(state: State) {
    return state.monitoring.listPage;
}

export function selectCheckboxShow(state: State) {
    return state.monitoring.checkboxShow;
}

export function selectDocIds(state: State) {
    return state.monitoring.docIds;
}

export function selectPreviewModalOpen(state: State) {
    return state.monitoring.previewModalOpen;
}

export function selectSortType(state: State) {
    return state.monitoring.sortType;
}

export function selectImagesLoading(state: State) {
    return state.monitoring.imagesLoading;
}

export function setStorageSortType(sortType: string | undefined) {
    window.localStorage.setItem("sort", sortType);
}

export function getSortType() {
    let item;
    const storageItem = window.localStorage.getItem("sort");
    try {
        item = storageItem;
    } catch {
        item = undefined;
    }
    return item ? item : undefined;
}

export function setStoragePage(page: number) {
    window.localStorage.setItem("page", `${page}`);
}

export function getStoragePage() {
    let item;
    const storageItem = window.localStorage.getItem("page");
    try {
        item = parseInt(storageItem);
    } catch {
        item = 0;
    }
    return item ? item : 0;
}
