import {Thunk, TypedAction} from "../store/types";
import {push} from "connected-react-router";
import {ROUTES} from "../app/Routes";
import {getBalanceApiObj} from "../api/balanceApi";
import {setUploadPopupOpen} from "./upload";

const SET_USER_LOGIN = "AUTH/SET_USER_LOGIN";
const SET_USER_LOGGED_IN = "AUTH/SET_USER_LOGGED_IN";
const SET_USER_PASSWORD = "AUTH/SET_USER_PASSWORD";
export const SET_USER_BALANCE = "AUTH/SET_USER_BALANCE";
export const UPDATE_AUTH_CLEAR = "AUTH/UPDATE_AUTH_CLEAR";

export type AuthActions =
    | TypedAction<typeof SET_USER_LOGIN, string>
    | TypedAction<typeof UPDATE_AUTH_CLEAR, undefined>
    | TypedAction<typeof SET_USER_LOGGED_IN, boolean>
    | TypedAction<typeof SET_USER_BALANCE, number>
    | TypedAction<typeof SET_USER_PASSWORD, string>;

export interface IParsedToken {
    ApplicationKey: string;
    ActiveAccountId: string;
    Id: string;
    Password: string;
    OrganizationId: string;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    Mail: string;
    exp: number;
}

export interface IAuthState {
    id: number;
    login: string;
    token?: string;
    password: string;
    isLoggedIn: boolean;
    balance: string;
}

export interface IBalance {
    pagesLeft: string,
}

export const initialState: IAuthState = {
    id: 0,
    login: "",
    isLoggedIn: false,
    password: "",
    balance: "0",
}

export function setIsLoggedIn(loggedIn: boolean) {
    return dispatch => {
        dispatch({type: SET_USER_LOGGED_IN, payload: loggedIn});
    }
}

export function getBalance(): Thunk {
    return async dispatch => {
        await getBalanceApiObj()
            .getAll()
            .then(response => response.json())
            .then(response => {
                const balance: IBalance = response;
                dispatch({
                    type: SET_USER_BALANCE,
                    payload: balance.pagesLeft ? balance.pagesLeft : 0,
                })
            })
            .catch((err) => {
                if([401,403].includes(err.status)){
                    dispatch(push(ROUTES.Auth))
                }
                console.log('get balance error', err)
            })
    }
}

export function getAuthBalance(): Thunk {
    return async dispatch => {
        await getBalanceApiObj()
            .getAll()
            .then(() => {
                dispatch(push(ROUTES.Monitoring))
                dispatch(setUploadPopupOpen(true))
            })
            .catch((err) => {
                dispatch(push(ROUTES.Auth))
                console.log('get balance error', err)
            })
    }
}

export function onChangeAuthData(name: keyof IAuthState, value: string) {
    return dispatch => {
        switch (name) {
            case "isLoggedIn":
                return dispatch({type: SET_USER_LOGGED_IN, payload: value})
            case "login":
                return dispatch({type: SET_USER_LOGIN, payload: value})
            case "password":
                return dispatch({type: SET_USER_PASSWORD, payload: value})
        }
    }
}

export function logout(): Thunk {
    return async dispatch => {
        dispatch(push(ROUTES.Auth));
        window.localStorage.clear();
        dispatch({type: UPDATE_AUTH_CLEAR, payload: undefined});
    };
}

export function authReducer(
    state: IAuthState = initialState,
    action: AuthActions
): IAuthState {
    switch (action.type) {
        case UPDATE_AUTH_CLEAR:
            return {...initialState};
        case SET_USER_LOGIN:
            return {
                ...state,
                login: action.payload,
            };
        case SET_USER_PASSWORD:
            return {
                ...state,
                password: action.payload,
            };
        case SET_USER_LOGGED_IN:
            return {
                ...state,
                isLoggedIn: action.payload,
            };
        case SET_USER_BALANCE:
            return {
                ...state,
                balance: action.payload,
            };
        default:
            return state;
    }
}

