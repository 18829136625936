interface IRoutes {
    Home: string;
    Upload: string;
    Auth: string;
    Monitoring: string;
    Diff: string;
    Info: string;
}

export const ROUTES: IRoutes = {
    Home: "/",
    Auth: "/auth",
    Monitoring: "/list",
    Upload: "/uploading",
    Diff: "/diff",
    Info: "/info",
}