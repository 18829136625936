import {ENDPOINTS} from "./endpoints";
import {BaseApi} from "./baseApi";

class BalanceApi extends BaseApi<any, any>{
    Endpoint = ENDPOINTS.balance
}

export function getBalanceApiObj() {
    return new BalanceApi()
}
