import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";
import Scrollbar from "react-scrollbars-custom";
import {Button, createTheme, IconButton, StyledEngineProvider, TextField, ThemeProvider} from "@mui/material";
import {DifferenceDocument, PageParts} from "../../components/differenceDocument/DifferenceDocument";
import PageWrapper from "../../components/pageWrapper/PageWeapper";
import styles from './DifferencePage.module.scss';
import {PopupElement} from "../../ducks/popup";
import ReportIcon from '@mui/icons-material/Report';
import {
    IDetailedDocument,
    IDifference,
    IDiscrepancyStatus,
    IDocumentImage,
    ITechReport,
    IUpdateDocument
} from "../../api/monitoringApi";
import {truncate} from "../../helper/other";
import {MonitoringStatuses} from "../../ducks/montitoring";
import {useDraggable} from "react-use-draggable-scroll";
import {DocumentDifferenceLoaderConnected} from "../../components/urlLoader/DocumentDifferenceLoaderConnected";
import {DifferenceListConnected} from "../../components/differenceList/DifferenceListConnected";
import {isIE} from "rsuite/utils";

export interface IDifferencePageStateProps {
    title: string;
    comment: string;
    report: string;
    reportPackageId: number[];
    packageId: any;
    detailedDiff: string | undefined;
    detailedDocLeftId: string | undefined;
    detailedDocLeft: IDetailedDocument | undefined;
    detailedDocRight: IDetailedDocument | undefined;
    detailedDocRightId: string | undefined;
    isTrackDisabled: boolean;
    imagesLeft: IDocumentImage[];
    imagesRight: IDocumentImage[];
    leftScale: number;
    rightScale: number;
    docStatus: MonitoringStatuses;
}

export interface IDifferencePageDispatchProps {
    setDetailedDiff: (diff: string | undefined) => void;
    popupPush: (popup: PopupElement) => void;
    onClearDifferencePage: () => void;
    routeTo: (route: string | null, id?: number) => void;
    updateDocumentStatus: (documentId: string, props: IUpdateDocument, helpForm?: boolean) => void;
    setDifferenceStatus: (index: number, status: IDiscrepancyStatus) => void;
    deleteUnrecognizedPackagesImages: (packageID: number) => void;
    setLeftScale: (leftScale: number) => void;
    setRightScale: (rightScale: number) => void;
    setIsTrackDisabled: (flag: boolean) => void;
    sendTechnicalReport: (props: ITechReport) => void;
    updateTechReportText: (value: string) => void;
}

interface IDifferenceListProps {
    images: IDocumentImage[];
    detailedDoc: IDetailedDocument | undefined;
    detailedDocId: string;
    scale: number;
    detailedDiff: string | undefined;
    setDetailedDiff: (diff: string | undefined) => void;
    setScale: (scale: number) => void;
    zoom: number;
    rotate: number;
    onLoad?: () => void;
    side: PageParts,
    docContRef: React.RefObject<HTMLDivElement>
}

const DifferenceListComponent = ({
                                     zoom,
                                     detailedDiff,
                                     detailedDoc,
                                     setDetailedDiff,
                                     scale,
                                     detailedDocId,
                                     setScale,
                                     images,
                                     rotate,
                                     onLoad,
                                     side,
                                     docContRef
                                 }: IDifferenceListProps) => {
    return images.map((diff, index) => {
        let firstDetailedDiff: string = '0';
        let newDiffs: IDifference[] = [];

        (detailedDoc && detailedDoc.docflowDocumentData && detailedDoc.docflowDocumentData.extendData) && detailedDoc.docflowDocumentData.extendData.discrepancyData.forEach((element, indexDiff) => {
            if (element.details) {
                const findDiff = element.details.find(detail => (detail.documentPageIndex === index && detail.documentId === detailedDocId));
                if (findDiff) {
                    newDiffs.push({...findDiff, apiIndex: element.apiIndex, discrepancyIndex: indexDiff});
                }
            }

        })

        return <DifferenceDocument
            setScale={setScale}
            imageSrc={diff.fileUrl}
            rotate={rotate}
            zoom={zoom}
            scale={scale}
            items={newDiffs}
            pageIndex={index}
            side={side}
            firstDetailedDiff={firstDetailedDiff}
            setDetailedDiff={setDetailedDiff}
            detailedDiff={detailedDiff}
            onLoad={onLoad}
            docContRef={docContRef}
        />
    })
}

//@ts-ignore
const DifferenceDocumentList = React.memo(DifferenceListComponent);

export type DifferencePageProps = IDifferencePageStateProps & IDifferencePageDispatchProps;

export interface IDifferenceDocumentPageState {
    scaleLeft: number,
    scaleRight: number,
    zoomLeft: number,
    zoomRight: number,
    rotateLeft: number,
    rotateRight: number,
    mouseRight: boolean,
    mouseLeft: boolean,
    comment: string,
}

const initialState: IDifferenceDocumentPageState = {
    scaleLeft: 0,
    scaleRight: 0,
    zoomLeft: 1,
    zoomRight: 1,
    rotateLeft: 0,
    rotateRight: 0,
    mouseRight: false,
    mouseLeft: false,
    comment: '',
}

export interface IDocumentButton {
    className: string;
    title: string;
    onClickLeft: () => void;
    onClickRight: () => void;
}

export function DifferencePage(props: DifferencePageProps) {
    const [state, setState] = useState({...initialState});
    const {
        comment,
        detailedDiff,
        imagesLeft,
        imagesRight,
        detailedDocLeftId,
        detailedDocRightId,
        detailedDocLeft,
        detailedDocRight
    } = props;
    let icon;
    switch (props.docStatus) {
        case MonitoringStatuses.ACTS_NOT_RECOGNISED:
            icon = styles.actsNotRecognized;
            break;
        case MonitoringStatuses.WAITING_FOR_PAIR:
            icon = styles.waitingForPair;
            break;
        case MonitoringStatuses.ACTS_REJECTED:
            icon = styles.actsRejected;
            break;
        case MonitoringStatuses.ACTS_PASSED:
            icon = styles.actPassed;
            break;
        case MonitoringStatuses.ACTS_MATCH:
            icon = styles.actsMatch;
            break;
        default:
            icon = styles.actsHaveDifference;
    }

    const onLeftDocLoad = useCallback(() => {
        props.setLeftScale(0)
        setState((prevState) => ({
            ...prevState,
            scaleLeft: 1,
            rotateLeft: 0,
        }))
    }, []);

    const onRightDocLoad = useCallback(() => {
        props.setRightScale(1);
        setState((prevState) => ({
            ...prevState,
            scaleRight: 1,
            rotateRight: 0,
        }))
    }, []);

    const onSetLeftScale = useCallback((value: number) => {
        props.setLeftScale(value)
    }, []);

    const onSetRightScale = useCallback((value: number) => {
        props.setRightScale(value)
    }, []);

    function preventScroll(e) {
        e.preventDefault();
        return false;
    }

    useEffect(() => {
        setState((prevState) => ({...prevState, comment: props.comment}))
    }, [comment])

    useLayoutEffect(() => {
        setTimeout(() => {
            const elementsScroll = [document.getElementById('documentsContainerLeft'), document.getElementById('documentsContainerLeft')];
            const leftScroll = elementsScroll[0];
            const rightScroll = elementsScroll[1];
            if (elementsScroll[0] && elementsScroll[1]) {
                // @ts-ignore
                refLeft.current.addEventListener('wheel', preventScroll, {passive: false});
                // @ts-ignore
                refLeft.current.addEventListener('mousewheel', preventScroll, {passive: false});
                // @ts-ignore
                refRight.current.addEventListener('wheel', preventScroll, {passive: false});
                // @ts-ignore
                refRight.current.addEventListener('mousewheel', preventScroll, {passive: false});
                // @ts-ignore
                const zoomLeft = leftScroll.clientHeight / 950;
                const zoomRight = rightScroll.clientHeight / 950;
                setState((prevState) => ({
                    ...prevState,
                    zoomLeft,
                    zoomRight,
                }))
            }
        }, 200)

        return () => {
            props.onClearDifferencePage();
        }
    }, []);

    useLayoutEffect(() => {
        setTimeout(() => {
            const elementsScroll = document.getElementsByClassName('ScrollbarsCustom trackYVisible');
            const leftScroll = elementsScroll[0];
            const rightScroll = elementsScroll[1];
            if (elementsScroll[0] && elementsScroll[1]) {
                const zoomLeft = leftScroll.clientHeight / 900;
                const zoomRight = rightScroll.clientHeight / 900;
                setState((prevState) => ({
                    ...prevState,
                    zoomLeft,
                    zoomRight,
                }))
            }
        }, 200)
    }, [imagesLeft.length, imagesRight.length, detailedDocRightId, detailedDocLeftId])

    const onSetDetailedDiff = useCallback((diff: string | undefined) => {
        if (diff) {
            props.setDetailedDiff(diff);
        }
    }, [])

    const onButtonSetRotate = (count: number, part: "rotateRight" | "rotateLeft") => {
        let prevRotate = state[part];
        prevRotate += count;
        if (prevRotate >= 360 || prevRotate <= -360) {
            prevRotate = 0;
        }
        setState((prevState) => ({...prevState, [part]: prevRotate}));
    }

    const refRight =
        useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
    const refLeft =
        useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
    const {events} = useDraggable(refLeft, {
        activeMouseButton: 'Left',
    });

    const right = useDraggable(refRight, {
        activeMouseButton: 'Left',
    });

    return <PageWrapper>
        <DocumentDifferenceLoaderConnected/>
        <div id={"PageContainerLeft"} className={styles.pageContainer}>
            <div className={styles.leftPart}>
                <div className={styles.rowItemContainer}>
                    <div className={styles.titleContainer}>
                        {(icon) && <div
                            style={{visibility: props.docStatus ? "visible" : "hidden"}}
                            title={props.title}
                            className={icon}
                        />}
                        <div className={styles.title}>
                            {props.detailedDocLeft && props.detailedDocLeft.docflowDocumentData.extendData.organization}
                        </div>
                    </div>
                    <div
                        id={'documentsContainerLeft'}
                        {...events}
                        ref={refLeft}
                        className={`${styles.documentsContainer} ${(props.leftScale <= 1) && styles.centering}`}
                    >
                        {props.imagesLeft.length > 0 && <DifferenceDocumentList
                            images={props.imagesLeft}
                            detailedDoc={detailedDocLeft}
                            detailedDocId={detailedDocLeftId}
                            setScale={onSetLeftScale}
                            rotate={state.rotateLeft}
                            zoom={state.zoomLeft}
                            scale={props.leftScale}
                            side={PageParts.Left}
                            setDetailedDiff={onSetDetailedDiff}
                            detailedDiff={props.detailedDiff}
                            onLoad={onLeftDocLoad}
                            docContRef={refLeft}
                        />}
                    </div>
                    <div className={styles.documentButtonsContainer}>
                        <IconButton
                            title={'Вписать'}
                            className={styles.scaleIcon}
                            onClick={() => {
                                props.setLeftScale(1)
                                setState((prevState) => ({
                                    ...prevState,
                                    scaleLeft: 1,
                                    rotateLeft: 0,
                                }))
                            }}
                        />
                        <IconButton
                            title={'Уменьшить'}
                            onClick={() => {
                                if (props.leftScale < 1.2) {
                                    return
                                }
                                props.setLeftScale(props.leftScale - 0.4)

                                const ratio = (((props.leftScale - 0.4) / props.leftScale))
                                const pagesCont = document.getElementById('pagesContainer')
                                const docCont: any = refLeft?.current
                                const offsetTop = docCont.clientHeight / 2 - pagesCont.offsetTop
                                docCont.scrollLeft = (docCont.scrollLeft + docCont.clientWidth / 2) * ratio - docCont.clientWidth / 2
                                docCont.scrollTop = (docCont.scrollTop + offsetTop) * ratio - offsetTop
                            }}
                            className={styles.zoomOutIcon}
                        />
                        <IconButton
                            title={'Увеличить'}
                            onClick={() => {
                                props.setLeftScale(props.leftScale + 0.4)

                                const ratio = (((props.leftScale + 0.4) / props.leftScale))
                                const pagesCont = document.getElementById('pagesContainer')
                                const docCont: any = refLeft?.current
                                const offsetTop = docCont.clientHeight / 2 - pagesCont.offsetTop
                                docCont.scrollLeft = (docCont.scrollLeft + docCont.clientWidth / 2) * ratio - docCont.clientWidth / 2
                                docCont.scrollTop = (docCont.scrollTop + offsetTop) * ratio - offsetTop
                            }}
                            className={styles.zoomInIcon}
                        />
                        <IconButton
                            title={'Повернуть влево'}
                            onClick={() => onButtonSetRotate(-90, "rotateLeft")}
                            className={styles.rotateLeftIcon}
                        />
                        <IconButton
                            title={'Повернуть вправо'}
                            onClick={() => onButtonSetRotate(90, "rotateLeft")}
                            className={styles.rotateRightIcon}
                        />
                    </div>
                </div>
                <div id='rowItemContainerRight' className={styles.rowItemContainer}>
                    <div className={styles.titleContainer}>
                        {(icon) && <div
                            style={{visibility: 'hidden'}}
                            className={icon}
                        />}
                        <div
                            title={props.detailedDocRight ? props.detailedDocRight.docflowDocumentData.extendData.organization : ""}
                            className={styles.title}>
                            {props.detailedDocRight && truncate(props.detailedDocRight.docflowDocumentData.extendData.organization, 44)}
                        </div>
                    </div>
                    <div
                        id={'documentsContainerRight'}
                        {...right.events}
                        ref={refRight}
                        className={`${styles.documentsContainer} ${(props.rightScale <= 1) && styles.centering}`}>

                        {props.imagesRight.length > 0 && <DifferenceDocumentList
                            images={props.imagesRight}
                            detailedDoc={detailedDocRight}
                            detailedDocId={detailedDocRightId}
                            setScale={onSetRightScale}
                            rotate={state.rotateRight}
                            zoom={state.zoomRight}
                            scale={props.rightScale}
                            side={PageParts.Right}
                            setDetailedDiff={onSetDetailedDiff}
                            detailedDiff={props.detailedDiff}
                            onLoad={onRightDocLoad}
                            docContRef={refRight}
                        />}
                    </div>
                    <div className={styles.documentButtonsContainer}>
                        <IconButton
                            title={'Вписать'}
                            className={styles.scaleIcon}
                            onClick={() => {
                                props.setRightScale(1)
                                setState((prevState) => ({
                                    ...prevState,
                                    scaleRight: 1,
                                    rotateRight: 0,
                                }))
                            }}
                        />
                        <IconButton
                            title={'Уменьшить'}
                            onClick={() => {
                                if (props.rightScale < 1.2) {
                                    return
                                }
                                props.setRightScale(props.rightScale - 0.4)

                                const ratio = (((props.rightScale - 0.4) / props.rightScale))
                                const pagesCont = document.getElementById('pagesContainer')
                                const docCont: any = refRight?.current
                                const offsetTop = docCont.clientHeight / 2 - pagesCont.offsetTop
                                docCont.scrollLeft = (docCont.scrollLeft + docCont.clientWidth / 2) * ratio - docCont.clientWidth / 2
                                docCont.scrollTop = (docCont.scrollTop + offsetTop) * ratio - offsetTop
                            }}
                            className={styles.zoomOutIcon}
                        />
                        <IconButton
                            title={'Увеличить'}
                            onClick={() => {
                                props.setRightScale(props.rightScale + 0.4)

                                const ratio = (((props.rightScale + 0.4) / props.rightScale))
                                const pagesCont = document.getElementById('pagesContainer')
                                const docCont: any = refRight?.current
                                const offsetTop = docCont.clientHeight / 2 - pagesCont.offsetTop
                                docCont.scrollLeft = (docCont.scrollLeft + docCont.clientWidth / 2) * ratio - docCont.clientWidth / 2
                                docCont.scrollTop = (docCont.scrollTop + offsetTop) * ratio - offsetTop
                            }}
                            className={styles.zoomInIcon}
                        />
                        <IconButton
                            title={'Повернуть влево'}
                            onClick={() => onButtonSetRotate(-90, "rotateRight")}
                            className={styles.rotateLeftIcon}
                        />
                        <IconButton
                            title={'Повернуть вправо'}
                            onClick={() => onButtonSetRotate(90, "rotateRight")}
                            className={styles.rotateRightIcon}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.rightPart}>
                <div className={styles.titleRow}>
                    <div className={`${styles.title} ${styles.titleNoMargin}`}>
                        {props.detailedDocLeft && props.detailedDocLeft.docflowDocumentData.extendData.discrepancyData.length > 0
                            ? 'Обнаруженные расхождения'
                            : props.title
                                ? props.title
                                : ''
                        }
                    </div>
                </div>
                <div className={`${styles.commentsContainer} ${styles.commentsEmptyContainer}`}>
                    <Scrollbar width={"100%"} height={"80%"}>
                        <DifferenceListConnected/>
                    </Scrollbar>
                </div>
                <ThemeProvider
                    theme={createTheme({
                        components: {
                            MuiOutlinedInput: {

                                styleOverrides: {
                                    root: {
                                        padding: '8px',
                                    }
                                }
                            }
                        }
                    })}
                >
                    <TextField
                        sx={{
                            marginTop: "20px",
                            backgroundColor: "#FFF",
                        }}
                        id="standard-multiline-static"
                        multiline
                        minRows={2}
                        maxRows={6}
                        variant="outlined"
                        value={state.comment}
                        onChange={(event) => {
                            const value = event.target.value;
                            setState((prevState) => ({
                                ...prevState,
                                comment: value,
                            }));
                        }}
                    />
                </ThemeProvider>
                <div className={styles.buttonsContainer}>
                    <StyledEngineProvider
                        injectFirst
                    >
                        {props.docStatus !== MonitoringStatuses.ACTS_PASSED && <React.Fragment>
                            <Button
                                disabled={props.docStatus === MonitoringStatuses.ACTS_NOT_RECOGNISED}
                                onClick={() => {
                                    props.popupPush({
                                        actionTitle: "Принять",
                                        actionVisible: true,
                                        data: ["Вы действительно хотите принять акт?"],
                                        actionHandler: () => {
                                            props.updateDocumentStatus(props.detailedDocLeftId, {
                                                comment: state.comment,
                                                status: 14,
                                            })
                                            props.routeTo("/")
                                        },
                                        cancelVisible: true,
                                    });
                                }}
                                title={"Акт принят"}
                                variant={'contained'}
                                color='success'
                                className={styles.materialButton}
                            >
                                {<div className={styles.checkIcon}/>}
                                <div>
                                    Принять
                                </div>
                            </Button>
                            <Button
                                disabled={props.docStatus === MonitoringStatuses.ACTS_NOT_RECOGNISED}
                                className={`${styles.materialButtonColor} ${props.docStatus === MonitoringStatuses.ACTS_NOT_RECOGNISED && styles.disabled}`}
                                title={"Отложен"}
                                variant={'contained'}
                                color='primary'
                                onClick={() => {
                                    props.popupPush({
                                        actionTitle: "Отложить",
                                        actionVisible: true,
                                        data: ["Вы хотите отложить обработку акта?"],
                                        actionHandler: () => {
                                            props.updateDocumentStatus(props.detailedDocLeftId, {
                                                comment: state.comment,
                                                status: props.docStatus,
                                            })
                                            props.routeTo("/")
                                        },
                                        cancelVisible: true,
                                    });
                                }}
                            >
                                {<div className={styles.stopIcon}/>}
                                Отложить
                            </Button>
                            <Button
                                disabled={props.docStatus === MonitoringStatuses.ACTS_NOT_RECOGNISED}
                                title={"Акт отклонён"}
                                className={styles.materialButton}
                                variant={'contained'}
                                color='error'
                                onClick={() => {
                                    props.popupPush({
                                        actionTitle: "Отклонить",
                                        actionVisible: true,
                                        data: ["Вы действительно хотите отклонить акт?"],
                                        actionHandler: () => {
                                            props.updateDocumentStatus(props.detailedDocLeftId, {
                                                comment: state.comment,
                                                status: 15,
                                            })
                                            props.routeTo("/")
                                        },
                                        cancelVisible: true,
                                    });
                                }}
                            >

                                {<div className={styles.crossIcon}/>}
                                Отклонить
                            </Button>
                        </React.Fragment>}
                        <Button
                            onClick={() => {
                                props.popupPush({
                                    technicalSupport: true,
                                    actionTitle: "Отправить",
                                    actionVisible: true,
                                    data: ["Вы действительно хотите отправить информацию об ошибке в техническую поддержку?"],
                                    actionHandler: () => {
                                        props.updateDocumentStatus(props.detailedDocLeftId, {
                                            comment: state.comment,
                                            status: 13,
                                        }, true)
                                        props.sendTechnicalReport({
                                            message: props.report,
                                            packageId: props.reportPackageId,
                                            subject: "DocShow: Сверка актов сверки",
                                        })
                                        props.routeTo("/")
                                    },
                                    cancelHandler: () => {
                                        props.updateTechReportText("");
                                    },
                                    cancelVisible: true,
                                });
                            }}
                            title={"Помощь"}
                            className={styles.materialButtonHelp}
                            variant={'contained'}
                        >
                            <ReportIcon sx={{paddingRight: '4px', zoom: isIE() ? 0.7 : 1, marginTop:  isIE() ? '8px' : '',  paddingBottom: '2px'}}
                                        fontSize={"large"}/> Сообщить об ошибке
                        </Button>
                        <Button
                            className={styles.materialButtonDelete}
                            onClick={() => {
                                props.popupPush({
                                    actionTitle: "Удалить",
                                    actionVisible: true,
                                    data: ["Вы действительно хотите удалить данный акт?"],
                                    actionHandler: () => {
                                        if (props.docStatus === MonitoringStatuses.ACTS_NOT_RECOGNISED) {
                                            props.deleteUnrecognizedPackagesImages(props.packageId);
                                        } else {
                                            props.updateDocumentStatus(props.detailedDocLeftId, {
                                                comment: state.comment,
                                                status: MonitoringStatuses.DELETED,
                                            })
                                        }
                                        props.routeTo("/")
                                    },
                                    cancelVisible: true,
                                });
                            }}
                            size={"small"}
                            title={"Удалить"}
                        >
                            {<div
                                className={styles.deleteIcon}
                            />}
                            Удалить

                        </Button>

                    </StyledEngineProvider>
                    <div className={styles.buttonsContainerRight}>
                    </div>
                </div>
            </div>
        </div>
    </PageWrapper>
}
