import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {State} from "../../store/rootReducers";
import {selectComments, selectDetailedDiff, selectDetailedDocuments,} from "../../selectors/monitoring";
import {setDetailedDiff, setDifferenceStatus} from "../../ducks/montitoring";
import {DifferenceList, IDifferenceListDispatchProps, IDifferenceListStateProps} from "./DifferenceList";

const mapStateToProps: MapStateToProps<IDifferenceListStateProps, {}, State> = state => {
    const detailedDiff = selectDetailedDiff(state);
    const documents = selectDetailedDocuments(state);
    let title = '';
    let documentLeft = undefined;


    if (documents.length === 2) {
        if (documents[0] && documents[0].docflowDocumentData && documents[0].docflowDocumentData.extendData && documents[0].docflowDocumentData.extendData.discrepancyData && documents[0].docflowDocumentData.extendData.discrepancyData.length > 0) {
            documentLeft = documents[0];
        }
    }

    return {
        title,
        detailedDiff,
        detailedDocLeft: documentLeft,
    }
};

const dispatchProps: MapDispatchToProps<IDifferenceListDispatchProps, {}> = {
    setDetailedDiff,
    setDifferenceStatus,
};

export const DifferenceListConnected = connect(
    mapStateToProps,
    dispatchProps,
)(DifferenceList);
