import {PageParts} from "../components/differenceDocument/DifferenceDocument";
import {getStoreUtils} from "../store/utils";
import {selectDetailedDiff} from "../selectors/monitoring";
import jwt_decode from "jwt-decode";
import moment from "moment";

export const TEST_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJBcHBsaWNhdGlvbktleSI6Ijg3MDNmNjVmLWExMTAtNGJhMS05ZTQ5LTA5N2I3MmYwMGY3NyIsIklkIjoiNjc4NiIsIlBhc3N3b3JkIjoiQ0U1NzFDRDhDNUVFRDg1QUNFMjgyN0M4RjE4QTgxNDMzQTlCQUM5NEQzQzlBNzk5M0QxOUFFNUE1M0FFODI0NCIsIkZpcnN0TmFtZSI6ItCQ0LrRgtGLINGB0LLQtdGA0LrQuCDQltC10L3QtSIsIk1pZGRsZU5hbWUiOiIiLCJMYXN0TmFtZSI6ItCQ0LrRgtGLINGB0LLQtdGA0LrQuCDQltC10L3QtSIsIk1haWwiOiIiLCJSb2xlIjoi0KLQtdGF0L3QuNGH0LXRgdC60LjQuSDQv9C-0LvRjNC30L7QstCw0YLQtdC70YwiLCJPcmdhbml6YXRpb25JZCI6IjE0MiIsIklzRGlzYWJsZWRCeU9yZ2FuaXphdGlvbkFkbWluIjoiRmFsc2UiLCJJc0Jsb2NrZWRCeUNvcnJlY3RBZG1pbiI6IkZhbHNlIiwiSXNGb3IxQ0xvYWRlciI6IkZhbHNlIiwiSXNGb3IxQ0V4dGVuc2lvbiI6IlRydWUiLCJleHAiOjE2Nzg2NTUwMzEsImlzcyI6IkF1dGhTZXJ2aWNlIn0.9Ya9RIV_iOE5RdixrCWgkun9MPLcAd1pI0lGh0VqG_8'

export const PACKAGE_ID_STORAGE_NAME = 'packageId';

export const PACKAGE_TTL = 3_600_000

export interface FullName {
    firstName: string;
    middleName: string;
    lastName: string;
}

export function getLocaleStorageItem(cname: string) {
    return window.localStorage.getItem(cname);
}

export function setLocaleStorageItem(name: string, authState: object, exdays: number) {
    if (exdays <= 0) {
        window.localStorage.clear();
    } else {
        window.localStorage.setItem(`${name}`, JSON.stringify(authState));
    }
}

export function inIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

export const handleDoubleLinkClick = (href: string) => {
    const {dispatch, getState} = getStoreUtils();
    const detailedDiff = selectDetailedDiff(getState());
    if (detailedDiff !== href) {
        let link1 = document.createElement('a');
        let link2 = document.createElement('a');
        document.body.append(link1);
        document.body.append(link2);
        link1.href = `#${href}${PageParts.Left}`;
        link2.href = `#${href}${PageParts.Right}`;
        setTimeout(() => {
            link2.click();
            document.body.removeChild(link2);
        }, 10)
        link1.click();

        document.body.removeChild(link1); //remove the link when done
        //remove the link when done
    }
}


export function truncate(str: string, maxlength: number, shorter: string = '...'): string {
    return str
        ? (str.length > maxlength) && maxlength !== 0
            ? str.slice(0, maxlength - 1).trim() + shorter
            : str
        : "";
}

export const findPageRec = (page: number, arr: number[]) => {
    if (page <= 0 || arr.length === 0) return undefined;
    const findPage = arr.find(elem => elem >= page);
    if (findPage) {
        return findPage;
    } else {
        return findPageRec(page - 1, arr);
    }
}
export const findNextIndex = (page: number, arr: any[]) => {
    if (page < 0 || arr.length === 0) return undefined;
    const index = arr[page];
    if (index) {
        return page;
    } else {
        return findNextIndex(page - 1, arr);
    }
}

export function getCookie(cname) {
    try {
        let name = encodeURIComponent(cname) + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return decodeURIComponent(c.substring(name.length, c.length));
            }
        }
    } catch (e) {
        console.log("getCookie error", e)
    }

    return "";
}

export function setCookie(name, value, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = encodeURIComponent(name) + "=" + value + ";" + expires + ";path=/";
}

export function parseJwt(token: string) {
    try {
        return jwt_decode(token, {header: true});
    } catch (e) {
        console.log('parseJwt error', e)
    }
}

export function getInitials(fullName: FullName, format: "lastname_and_initials" | "short" | "full") {
    let initials = '';
    const lastName = fullName.lastName ? fullName.lastName : "";
    const firstName = fullName.firstName ? fullName.firstName : "";
    const middleName = fullName.middleName ? fullName.middleName : "";

    switch (format) {
        case "short": {
            initials = `${lastName[0]}${firstName[0]}`.toUpperCase();
        }
            break;
        case "lastname_and_initials": {
            initials = `${lastName} ${firstName ? firstName[0].toUpperCase() + '.' : ''} ${middleName ? middleName[0].toUpperCase() + '.' : ''}`;
        }
            break;
        case "full": {
            initials = `${lastName} ${firstName}${middleName ? ' ' + middleName : ''}`;
        }
            break;
    }

    return initials
}

export async function asyncEncodeImageFileAsURL(file: any) {
    return new Promise(resolve => {
        const reader = new FileReader();

        reader.onloadend = () => {
            if (typeof reader.result === 'string') {
                resolve(reader.result);
            }
        };

        reader.readAsDataURL(file);
    });
}

export function getObjectFitSize(contains /* true = contain, false = cover */, containerWidth, containerHeight, width, height) {
    let doRatio = width / height;
    let cRatio = containerWidth / containerHeight;
    let targetWidth = 0;
    let targetHeight = 0;
    let test = contains ? (doRatio > cRatio) : (doRatio < cRatio);

    if (test) {
        targetWidth = containerWidth;
        targetHeight = targetWidth / doRatio;
    } else {
        targetHeight = containerHeight;
        targetWidth = targetHeight * doRatio;
    }

    return {
        width: targetWidth,
        height: targetHeight,
        x: (containerWidth - targetWidth) / 2,
        y: (containerHeight - targetHeight) / 2
    };
}

export const getToken = (name: string = 'jwt-token') => {
    return getCookie(name);
}
export const divideNumbersByPosition = (number: number | string) => {
    return number ? number.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ') : 0;
}

export const tableCustomDateSort = (dateA: number | string, dateB: number | string, format: string): number => {
    if (dateA === null || dateB === null)
        return dateA === null && dateB !== null
            ? -1
            : dateA !== null && dateB === null
                ? 1
                : 0;

    const date1 = moment(dateB, format).valueOf();
    const date2 = moment(dateA, format).valueOf();

    return date1 > date2 ? -1 : date1 < date2 ? 1 : 0;
}

export const equalPaths = (path, route) => {
    if ((path && route) !== undefined && (path && route) !== null) {
        return path.split('/').join('') === route.split('/').join('')
    }
}

const isIE = () => {
    //@ts-ignore
    return !!document.documentMode;
}
