import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {IPreviewModalDispatchProps, IPreviewModalStateProps, PreviewModal} from "./PreviewModal";
import {State} from "../../store/rootReducers";
import {
    selectDetailedDocIdLeft,
    selectDocIds,
    selectImagesLoading,
    selectLeftImages,
    selectPreviewModalOpen
} from "../../selectors/monitoring";
import {handleCreateDocsPair, onClearDifferencePage, setPreviewModalOpen} from "../../ducks/montitoring";

const mapStateToProps: MapStateToProps<IPreviewModalStateProps, {}, State> = state => {
    let props: IPreviewModalStateProps = {
        modalOpen: false,
        imagesLoading: false,
        images: [],
        docIds: [],
        docId: "",
    };

    props.docId = selectDetailedDocIdLeft(state);
    props.modalOpen = selectPreviewModalOpen(state);
    props.imagesLoading = selectImagesLoading(state);
    props.images = selectLeftImages(state);
    props.docIds = selectDocIds(state);

    return {...props}
};

const dispatchProps: MapDispatchToProps<IPreviewModalDispatchProps, {}> = {
    setPreviewModalOpen,
    onClearDifferencePage,
    handleCreateDocsPair,
};

export const PreviewModalConnected = connect(
    mapStateToProps,
    dispatchProps,
)(PreviewModal);
