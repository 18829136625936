import * as React from "react";

export interface UrlLoaderStateProps {
    urlId: string | number | undefined;
    useGetForEdit?: boolean;
}

export interface UrlLoaderDispatchProps {
    onRequestById: (id: number | string) => void;
    onRequestByIdEdit?: (id: number | string) => void;
    setItemId: (id: number | string) => void;
}

export type UrlLoaderProps = UrlLoaderStateProps & UrlLoaderDispatchProps;

interface UrlLoaderState {}

export class UrlLoader extends React.Component<UrlLoaderProps, UrlLoaderState> {
    state = {};

    componentDidMount(): void {
        //@ts-ignore
        const {urlId, onRequestById, setItemId, onRequestByIdEdit, useGetForEdit} = this.props;

        (onRequestByIdEdit && useGetForEdit) && onRequestByIdEdit(urlId);

        if (urlId) {
            onRequestById(urlId);
            setItemId(urlId);
        }
    }

    public render() {
        return <></>;
    }
}
