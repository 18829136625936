import {useEffect} from 'react';

export const useChatScript = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript'
        script.src = 'https://pyrus.com/js/webwidget?id=cYiQ7a6YSAMwYeWUbSIaqXrF5zjjUZIh7rfVIWzXxUWu0gxSbG8WbyLkOdb1vhpxvpQrfgvXSS7q~zPXExqyCGf~3AE~fIZnqwM9QPYL-abobZhajG2SHY1rkU2vP9u4kaGgRg%3D%3D';
        script.async = true;

        document.body.append(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);
};

export const useMetricsScript = () => {
    const script = document.createElement('script');
    script.type = 'text/javascript'
    useEffect(() => {
        //@ts-ignore
        if(process.env.REACT_APP_BASE_API_URL || window.REACT_APP_TOGGLE_METRICS){

            script.src = ' (function (m, e, t, r, i, k, a) {\n' +
                '        m[i] = m[i] || function () {\n' +
                '            (m[i].a = m[i].a || []).push(arguments)\n' +
                '        };\n' +
                '        m[i].l = 1 * new Date();\n' +
                '        for (var j = 0; j < document.scripts.length; j++) {\n' +
                '            if (document.scripts[j].src === r) {\n' +
                '                return;\n' +
                '            }\n' +
                '        }\n' +
                '        k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a)\n' +
                '    })\n' +
                '    (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");\n' +
                '\n' +
                '    ym(93165650, "init", {\n' +
                '        clickmap: true,\n' +
                '        trackLinks: true,\n' +
                '        accurateTrackBounce: true,\n' +
                '        webvisor: true\n' +
                '    });';
            script.async = true;

            document.body.append(script);
        }

        return () => {
            document.body.removeChild(script);
        }
    }, []);
};