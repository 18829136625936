import {createTheme} from "@mui/material";

export const themeSelect = createTheme({
    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: "#F57B20",
                    borderColor: "green",
                    paddingLeft: '0px !important',
                }
            }
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    paddingLeft: '0px !important',
                    color: "#F57B20",
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                icon: {
                    color: "#F57B20",
                },
                nativeInput: {
                    color: "#F57B20",
                },
                outlined: {
                    color: "#F57B20",
                    paddingLeft: '0px !important',
                }
            }
        }
    }
});