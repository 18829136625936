import {getEndpoint, UrlPart} from "./endpoints";
//@ts-ignore
import queryString from "wretch/addons/queryString"

//@ts-ignore

export interface PropsChunk {
    limit: number;
    offset: number
}

export interface IPropsList {
    page: number;
    length?: number;
    accesses?: string
}

export class BaseApi<BaseForCreate, BaseForUpdate> {
    Endpoint: string

    endpoint(urlPart: UrlPart, EndpointRow?: string) {
        return getEndpoint(EndpointRow ? EndpointRow : this.Endpoint, urlPart);
    }

    async getAll() {
        return await this.endpoint("", undefined,)
            .addon(queryString)
            .get()
            .res();
    }

    async getAllChunk(props: IPropsList) {
        if (!props.accesses) delete props.accesses;
        return await this.endpoint("", undefined,)
            .options(props)
            .get()
            .res()
            .then(async (r) => {
                if (!r) return;
                return {
                    data: await r.json(),
                    count: r.headers.get("total-count")
                };
            });
    }

    async getItem(id: number, withoutCompanyId: boolean = false) {
        const res = await this.endpoint(`${id}`, undefined)
            .get()
            .res();

        return res;
    }

    async getChunk(props: PropsChunk) {
        return await this.endpoint("")
            .addon(queryString)
            .query(props)
            .get()
            .res()
            .then(async r => {
                if (!r) return;
                return {
                    data: await r.json(),
                    count: r.headers.get("x-total-count")
                };
            });
    }

    async create(props: BaseForCreate, withoutCompanyId: boolean = false, companyId?: number) {
        return await this.endpoint("", undefined)
            .post(props)
            .res();
    }

    async update(id: number, props: BaseForUpdate, withoutCompanyId: boolean = false) {
        return await this.endpoint(`${id}`, undefined)
            .put(props)
            .res();
    }

    async delete(id: number, withoutCompanyId: boolean = false) {
        return await this.endpoint(`${id}`, undefined)
            .delete()
            .res();
    }

    async restore(id: number, withoutCompanyId: boolean = false) {
        return await this.endpoint(`${id}`)
            .put()
            .res();
    }
}

export function getBaseApiObj() {
    return new BaseApi();
}
