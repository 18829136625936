import React, {CSSProperties} from 'react';
import MaterialTable, {Column} from "material-table";
import {createTheme, Paper} from "@mui/material";
import {ThemeProvider} from "@mui/styles";
import {materialTableLocalizationRu} from "./materialTableLocalization";
import {TablePagination} from '@material-ui/core';
import {tableIcons} from "./tableIcons";
import styles from "./Table.module.scss"

export interface ITableProps {
    checkboxShow?: boolean;
    docIds?: string[];
    pageSize: number;
    initialPage?: number;
    columns: Column<Object>[];
    onChangeListPage?: (pageNumber: number) => void;
    data: Object[];
    onRowClick?: (event: any, rowData: any) => void;
    bodyHeight?: number;
}

export function Table(props: ITableProps) {
    const {columns, initialPage, pageSize, data, onRowClick, bodyHeight} = props;
    const defaultMaterialTheme = createTheme({});
    const prepareColumns = columns.map((col) => ({
        ...col,
        cellStyle: (col.field !== "status" && col.field !== "checkAction" && col.field !== "actionButton")
            ? {} as CSSProperties
            : {width: '2.5%',} as CSSProperties,
        headerStyle: (col.field !== "status" && col.field !== "checkAction" && col.field !== "actionButton")
            ? {
                zIndex: 0,
                backgroundColor: '#FFF',
                fontFamily: "Lato', sans-serif",
                fontSize: '20px',
                fontWeight: '400 !important',
                lineHeight: '24px',
                letterSpacing: '0em',
                textAlign: 'left',
                width: "auto !important",
            } as CSSProperties
            : {
                alignItems: 'center !important',
                zIndex: 0,
                backgroundColor: '#FFF',
                maxWidth: "40px",
                width: "40px !important",
                fontFamily: "Lato, sans-serif",
                fontSize: '20px',
                fontWeight: '400 !important',
                lineHeight: '24px',
                letterSpacing: '0em',

            } as CSSProperties
    }));
    const changePage = e => {
        if (props.onChangeListPage) {
            props.onChangeListPage(e)
        }
    }
    const updateMaterialTableKey = `MaterialTable_UPDATE_KEY_${Math.random()}`;

    return <div className={styles.tableContainer}>
        <ThemeProvider theme={defaultMaterialTheme}>
            <MaterialTable
                components={{
                    Container: props => <Paper {...props} elevation={0}/>,
                    Pagination: props1 => <div
                        className={styles.paginationAndButtonContainer}
                    >
                        <TablePagination {...props1}/>
                    </div>
                }}
                key={updateMaterialTableKey}
                columns={prepareColumns}
                data={data}
                localization={materialTableLocalizationRu}
                icons={tableIcons}
                onRowClick={onRowClick}
                options={{
                    rowStyle: {
                        zIndex: '0 !important',
                        fontFamily: "'Lato', sans-serif",
                        fontSize: '20px',
                        fontWeight: '300 !important',
                        lineHeight: '24px',
                        letterSpacing: '0em',
                    },
                    initialPage: initialPage,
                    pageSizeOptions: [],
                    pageSize,
                    toolbar: false,
                    actionsColumnIndex: 7,
                    sorting: true,
                    thirdSortClick: true,
                    draggable: false,
                    search: false,
                    showTitle: false,
                    emptyRowsWhenPaging: false,
                    searchFieldAlignment: "left",
                    minBodyHeight: bodyHeight ? bodyHeight - 120 : undefined,
                    maxBodyHeight: bodyHeight ? bodyHeight - 118 : undefined,

                }}
                onChangePage={changePage}
            />
        </ThemeProvider>
    </div>
}
