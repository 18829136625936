import React, {FC, useEffect, useLayoutEffect, useState} from "react";
import styles from './navigationBar.module.scss';
import {Button, ClickAwayListener, createTheme, Grow, MenuItem, MenuList, Paper, Popper} from "@mui/material";
import {Link} from "react-router-dom";
import {ROUTES} from "../../app/Routes";
import {ThemeProvider} from "@mui/styles";
import {MonitoringStatuses} from "../../ducks/montitoring";
import {Icon} from '@iconify/react';

export interface NavigationBarStateProps {
    login: string;
    balance: number;
    header: string;
    isLoggedIn: boolean;
    docStatus: MonitoringStatuses;
    isDetailedPage: boolean;
    canGetBalance: boolean;
    activeAccountId: string;
    path: string;
}

export interface NavigationBarDispatchProps {
    onLogout: () => void;
    getBalance: () => void;
    setUploadPopupOpen: (isOpen: boolean) => void;
    onGetDifferenceProtocol: () => void;
    onDownloadPdf: () => void;
}

type NavigationBarProps = NavigationBarStateProps & NavigationBarDispatchProps;

const theme = createTheme({});

export const NavigationBar: FC<NavigationBarProps> = ({onGetDifferenceProtocol, onDownloadPdf,  activeAccountId, docStatus, path, getBalance, canGetBalance, setUploadPopupOpen, balance, isDetailedPage, login, isLoggedIn, header, onLogout}) => {
    const [openUserMenu, setOpenUserMenu] = useState(false);
    const disableGetDifferenceProtocolButton = [MonitoringStatuses.ACTS_PASSED, MonitoringStatuses.ACTS_MATCH].includes(docStatus);
    useEffect((() => {
        //@ts-ignore
        if (!activeAccountId && !process.env.REACT_APP_TEST_TOKEN && !process.env.REACT_APP_EXTERNAL_TOKEN_AUTH && path !== ROUTES.Auth) {
            window.location.href = process.env.REACT_APP_CORRECT_BASE_API + '/Integration/IntegrateWithReconciliationsActsService?redirectToDocshow=true'
        }
    }), [activeAccountId, path]);

    useLayoutEffect(() => {
        canGetBalance && getBalance();
    }, [canGetBalance, getBalance])

    return <header className={styles.container}>
        <div className={styles.leftContainer}>
            <div className={styles.iconContainer}>
                <Link to={"/"} className={styles.logo}>
                </Link>
                <div className={styles.title}>
                    <div>Сравнение</div>
                    <div>актов</div>
                    <div>сверки</div>
                </div>
            </div>
            {isLoggedIn &&
            <Button
                variant={'contained'}
                onClick={() => {
                    setUploadPopupOpen(true)
                }}
                className={`${styles.link} 
                ${styles.linkActive}`}
            >
                +&nbsp;&nbsp;Загрузить файл
            </Button>
            }
            {(isLoggedIn && isDetailedPage && (![MonitoringStatuses.WAITING_FOR_PAIR, MonitoringStatuses.ACTS_NOT_RECOGNISED].includes(docStatus))) &&
            <Button
                disabled={disableGetDifferenceProtocolButton}
                variant={'contained'}
                onClick={() => {
                    onGetDifferenceProtocol();
                }}
                className={`${styles.generateProtocol} 
                ${disableGetDifferenceProtocolButton ? styles.disabled : ""}`}
            >
                <div>
                    <Icon style={{objectFit: "contain"}} icon="mdi:microsoft-excel" color="#f57b20" width="20" height="20"/>
                </div>
                <span>Сформировать акт разногласий</span>
            </Button>
            }
            {(isLoggedIn && isDetailedPage && process.env.REACT_APP_EXTERNAL_TOKEN_AUTH) && <Button
                variant={'contained'}
                onClick={() => {
                    onDownloadPdf();
                }}
                className={`${styles.downloadCliche}`}
            >
                <div>
                    <Icon icon="carbon:generate-pdf" color="#f57b20" width="32" height="22" />
                </div>
                <span>Скачать с клише</span>
            </Button>}
        </div>
        <div className={styles.topPanelRight}>
            <div className={styles.row}>
                {isLoggedIn && <Link style={{textDecoration: "none"}} to={ROUTES.Info}>
                    <div className={styles.rowItem}>
                        <div className={styles.infoIcon}>
                        </div>
                        <div className={styles.textLink}>
                            О сервисе
                        </div>
                    </div>
                </Link>}
                {!isLoggedIn && <div
                    className={styles.rowItem}
                >
                    <a
                        href={`${process.env.REACT_APP_CORRECT_LK_LOGIN_LINK}`}
                    >
                        <Button
                            variant={'contained'}
                            className={styles.buttonAction}
                            size={"small"}
                        >
                            Войти
                        </Button>
                    </a>
                    <a href={`${process.env.REACT_APP_CORRECT_LK_REGISTRATION_LINK}`}>
                        <Button
                            variant={'contained'}
                            className={styles.buttonCancel}
                            size={"small"}
                        >
                            Начать бесплатно
                        </Button>
                    </a>
                </div>}
            </div>

            {isLoggedIn && <div className={styles.itemColumn}>
                <div
                    onClick={() => setOpenUserMenu(!openUserMenu)}
                    className={styles.topPanelRightAvatarCont}
                >
                    <span className={styles.topPanelRightAvatarUserName}>{login}</span>
                    <span className={styles.arrowDown}/>
                </div>
                <ThemeProvider theme={theme}>
                    <Popper
                        className={styles.topPanelRightAvatarMenu}
                        open={openUserMenu}
                        transition
                        disablePortal
                        style={{top: 60, left: "auto", right: 50}}
                    >
                        {({TransitionProps, placement}) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === "bottom" ? "center top" : "center bottom"
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={() => setOpenUserMenu(false)}>
                                        <MenuList
                                            id="menu-list-grow"
                                            onKeyDown={() => setOpenUserMenu(!openUserMenu)}
                                        >
                                            <MenuItem
                                                onClick={() => setOpenUserMenu(false)}
                                            >
                                                <a
                                                    rel={"noreferrer"}
                                                    target={'_blank'}
                                                    href={'https://lkk.correct.su/Billing/Tariffs'}
                                                >
                                                    Личный кабинет</a>
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() => {
                                                    onLogout()
                                                    setOpenUserMenu(false)
                                                }}>
                                                Выход
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </ThemeProvider>
                <div className={`${styles.row} ${styles.rowBalance}`}>
                {/*@ts-ignore*/}
                { !process.env.REACT_APP_EXTERNAL_TOKEN_AUTH &&
                    <div className={styles.rowItem}>
                        <div className={styles.text}>
                            Баланс:
                        </div>
                        <div className={styles.textBalance}>
                            {balance} страниц
                        </div>
                    </div>
                }
                </div>
            </div>}

        </div>
    </header>

}
