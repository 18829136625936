import {Redirect, Route, RouteProps} from "react-router";
import React from "react";
import {connect, MapStateToProps} from "react-redux";
import {State} from "../store/rootReducers";
import {ROUTES} from "./Routes";

export interface PrivateRouteStateProps {
    isAuthenticated: boolean
}

export interface PrivateRouteProps extends RouteProps, PrivateRouteStateProps {
    component: React.ComponentType<any>;
}

export function PrivateRoute({
  component: Component,
  ...rest
}: PrivateRouteProps) {
  const { isAuthenticated} = rest;

  if (!isAuthenticated) {
    return (
      <Route
        {...rest}
        render={props => (
          <Redirect
            to={{
              pathname: ROUTES.Auth,
              state: { from: props.location }
            }}
          />
        )}
      />
    );
  }

  return <Route {...rest} render={props => <Component {...props} />} />;
}

const mapStateToProps: MapStateToProps<
  PrivateRouteStateProps,
  {},
  State
> = state => {
  return {
      isAuthenticated: true,
  };
};

export const PrivateRouteConnected = connect(mapStateToProps)(PrivateRoute);
