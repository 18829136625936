import React, {useLayoutEffect} from "react";
import styles from "./PageWrapper.module.scss";
import {SpinnerConnected} from "../spinner/SpinnerConnected";
import {Footer} from "../footer/footer";
import {PopupDialogConnected} from "../popupDialog/PopupDialogConnected";
import {NavigationBarConnected} from "../navigationBar/navigationBarConnected";
import {UploadPopupConnected} from "../uploadPopup/UploadPopupConnected";
import {PreviewModalConnected} from "../previewModal/PreviewModalConnected";


export default function PageWrapper ({ children }) {
    useLayoutEffect(() => {
    }, [])

    return <div className={styles.wrapperContainer}>
        <PopupDialogConnected/>
        <UploadPopupConnected/>
        <SpinnerConnected/>
        <NavigationBarConnected/>
        <PreviewModalConnected/>
        <div id='pagesContainer' className={styles.pagesContainer}>
            {children}
        </div>
        <Footer/>
    </div>
}
