import React, {FC, useEffect, useLayoutEffect, useRef, useState} from "react";

import PageWrapper from "../../components/pageWrapper/PageWeapper";
import styles from "./InfoPage.module.scss";
import {Button, Paper} from "@mui/material";
import check from "./correctCheck.png";
//@ts-ignore
import video from './video.mov';
import {CorrectActionButton} from "./correct-action-button";
import {useWindowDimensions} from "../../hooks/use-windows-dimenssions";

export const PRICE_PAGE = [10, 7.5, 7, 6, 5.6, 5, 3.5, 2.7, 2.5, 2.2];
export const PRICE_PACKAGE = [1000, 1500, 3500, 6000, 14000, 25000, 35000, 54000, 125000, 220000];

type Tab = {
    title: string;
    timeValue: number;
}

type TabProps = Tab & {
    onTabClick: (time: number) => void;
    isActive: boolean;
};

const Tab: FC<TabProps> = ({timeValue, onTabClick, isActive, title}) => {
    return (
        <li
            className={`${styles.listElement} ${isActive ? styles.listElementActive : ""}`}
            onClick={() => onTabClick(timeValue)}
        >
            {title}
        </li>
    );
};

const getVisibleHeight = (element) => {
    const rect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;

    // Высота элемента внутри видимой области окна
    const visibleHeight = Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0);

    return Math.max(visibleHeight, 0); // Возвращаем 0, если элемент не виден
}

export const InfoPage: FC = () => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const ulRef = useRef<HTMLUListElement>(null);
    const paperRef = useRef<HTMLDivElement>(null);
    const h1Ref = useRef<HTMLHeadingElement>(null);
    const h2Ref = useRef<HTMLHeadingElement>(null);
    const [videoHeight,setVideoHeight] = useState<number>(0);
    useWindowDimensions();
    const tabTimes: Tab[] = [
        {
            title: "Сервис умеет работать с файлами: \n pdf, jpg, png, tiff, xls, xlsx",
            timeValue: 5,
        },
        {
            title: " Автоматически найдет пару для акта",
            timeValue: 9,
        },
        {
            title: "Подсветит расхождения",
            timeValue: 15,
        },
        {
            title: "Отобразит подсказки с указанием типа несоответствия",
            timeValue: 21,
        },
        {
            title: "Сформирует протокол разногласий для отправки контрагенту",
            timeValue: 29,
        },
    ]; // Временные значения для вкладок
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    useEffect(() => {
        const video = videoRef.current;

        const handleTimeUpdate = () => {
            const currentTime = video.currentTime ?? 0;
            const newActiveIndex = tabTimes.findIndex(({timeValue}, index) => {
                return currentTime >= timeValue && (index === tabTimes.length - 1 || (tabTimes[index + 1] && currentTime < tabTimes[index + 1]?.timeValue))
                }

            );
            setActiveIndex(newActiveIndex);
        };

        video?.addEventListener('timeupdate', handleTimeUpdate);

        return () => {
            video?.removeEventListener('timeupdate', handleTimeUpdate);
        };
    }, [tabTimes]);

    useLayoutEffect(() => {
        if(paperRef.current && ulRef.current && h1Ref.current && h2Ref.current){
            try {
                setVideoHeight(getVisibleHeight(paperRef.current) - ulRef.current.clientHeight - h1Ref.current.clientHeight - h2Ref.current.clientHeight - 230);
            }
            catch {
                setVideoHeight(700);
            }
        }
    }, [tabTimes])


    const handleTabClick = (time: number) => {
        if (videoRef.current) {
            videoRef.current.currentTime = time;
        }
    };


    return <PageWrapper>
        <Paper ref={paperRef} elevation={0} className={styles.container}>
            <h1 ref={h1Ref} className={styles.header}>
                Сервис сравнения актов сверки
            </h1>
            <h2 ref={h2Ref} className={`${styles.subHeader}`}>
                Сервис автоматически выполняет сравнение двух актов и выявляет расхождения
            </h2>
            <div className={`${styles.about} ${styles.aboutCenter}`}>
                <div className={styles.videoContainer}>
                    <ul ref={ulRef} className={styles.tabsContainer}>
                        {tabTimes.map(({timeValue, title}, index) => (
                            <Tab
                                title={title}
                                key={index}
                                timeValue={timeValue}
                                onTabClick={handleTabClick}
                                isActive={index === activeIndex}
                            />
                        ))}
                    </ul>
                    <video ref={videoRef} src={video} style={{objectFit: 'contain', height: `${videoHeight}px`, backgroundColor: "#FFF"}} autoPlay={true} controls={true}/>
                </div>
                <br/><br/>
                <a href={`${process.env.REACT_APP_CORRECT_LK_REGISTRATION_LINK}`}>
                    <CorrectActionButton
                        variant={'contained'}
                    >
                        Попробовать
                    </CorrectActionButton>
                </a>
                <br/><br/>
                При подключении 50 страниц предоставляется бесплатно,
                <br/>
                далее от 2 руб. за страницу.
                <div className={styles.linkContainer}>
                    <a href={'https://lkk.correct.su/Billing'} target={"_blank"}>Выбрать пакет страниц</a>
                </div>
            </div>
            <h2 className={styles.subHeader}>
                Дополнительные возможности для удобного использования <br/><br/>
                (инструменты предоставляются бесплатно)
            </h2>
            <div className={`${styles.about} ${styles.aboutLessPadding}`}>
                <div className={`${styles.list}`}>
                    <div className={styles.row}>
                        <div className={styles.leftItemFirst}>
                            <img style={{marginBottom: "8px"}} className={styles.liIcon} src={check} width={35}
                                 height={35} alt={''}/>
                            <div style={{height: "100%"}} className={styles.divider}/>
                        </div>
                        <br/>
                        <div className={styles.rightItem}>
                            <div>
                                Интеграция с 1С
                                <br/>
                                Воспользуйтесь готовой обработкой для автоматического формирования ответных актов
                                сверки.
                                <br/>
                                Сервис сам определит контрагента, период, договор, сформирует в 1С ответный акт сверки и
                                выполнит сравнение.
                                <br/>
                                <div className={styles.linkContainer}>
                                    <a href={'https://docs.correct.su/docs-utils/Сервис%20обмена%20файлами'}
                                       target={"_blank"}>Подключить</a>
                                </div>
                                <br/>
                            </div>
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.leftItemFirst}>
                            <img style={{marginBottom: "8px"}} className={styles.liIcon} src={check} width={35}
                                 height={35} alt={''}/>
                            <div style={{height: "100%"}} className={styles.divider}/>
                        </div>
                        <br/>
                        <div className={styles.rightItem}>
                            <div>
                                Загрузка из электронной почты
                                <br/>
                                Включите получение актов сверки из электронной почты и сервис сам будет их загружать и
                                обрабатывать.
                                <br/>
                                <div className={styles.linkContainer}>
                                    <a href={'https://docs.correct.su/docs-utils/Сервис%20обмена%20файлами'}
                                       target={"_blank"}>Подключить</a>
                                </div>
                                <br/>
                            </div>
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.leftItemFirst}>
                            <img style={{marginBottom: "8px"}} className={styles.liIcon} src={check} width={35}
                                 height={35} alt={''}/>
                            <div style={{height: "100%"}} className={styles.divider}/>
                        </div>
                        <br/>
                        <div className={styles.rightItem}>
                            <div>
                                Загрузка сканов из сетевой папки
                                <br/>
                                Настройте передачу файлов с вашего компьютера на сервис и все размещенные в папке акты
                                сверки будут автоматически обрабатываться сервисом.
                                <br/>
                                <div className={styles.linkContainer}>
                                    <a href={'https://docs.correct.su/docs-utils/Сервис%20обмена%20файлами'}
                                       target={"_blank"}>Подключить</a>
                                </div>
                                <br/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={` ${styles.about}`}>
                <div className={styles.linkContainer}>
                    <a href={'https://docs.correct.su/docs-utils/%D0%A1%D1%80%D0%B0%D0%B2%D0%BD%D0%B5%D0%BD%D0%B8%D0%B5%20%D0%B0%D0%BA%D1%82%D0%BE%D0%B2%20%D1%81%D0%B2%D0%B5%D1%80%D0%BA%D0%B8/'}
                       target={"_blank"}>Руководство пользователя</a>
                </div>
            </div>
        </Paper>
    </PageWrapper>
}
