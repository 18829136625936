import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {getBalance, IParsedToken, logout} from "../../ducks/auth";
import {NavigationBar, NavigationBarDispatchProps, NavigationBarStateProps,} from "./navigationBar";
import {State} from "../../store/rootReducers";
import {downloadPdf, getDifferenceProtocol, setUploadPopupOpen} from "../../ducks/upload";
import {selectHeader, selectPath} from "../../selectors/other";
import {equalPaths, getToken, parseJwt} from "../../helper/other";
import {selectBalance} from "../../selectors/auth";
import {MonitoringStatuses, PackageStatuses} from "../../ducks/montitoring";
import {selectDetailedDocIdLeft, selectDetailedDocuments} from "../../selectors/monitoring";
import {ROUTES} from "../../app/Routes";
import {IGetDifferenceProtocolProps} from "../../api/uploadApi";

const mapStateToProps: MapStateToProps<NavigationBarStateProps, {}, State> = state => {
    let login = "";
    let docStatus = MonitoringStatuses.ACTS_HAVE_DIFF;
    const path = selectPath(state);
    const balance =  selectBalance(state);
    const isDetailedPage = equalPaths(path, ROUTES.Diff);
    const token = getToken('user-info');
    let activeAccountId;
    let getDifferenceProtocol: IGetDifferenceProtocolProps | undefined;
    if(token) {
       const parsedToken = parseJwt(token) as IParsedToken;
       if(parsedToken){
           login = parsedToken.FirstName;
           activeAccountId = parsedToken.ActiveAccountId
           try {
               window.localStorage.setItem('PyrusWebWidgetUserName', `ID организации: ${parsedToken.OrganizationId},${parsedToken.FirstName},${parsedToken.Mail}`)
           }
           catch {

           }
       }
    }
    const documents = selectDetailedDocuments(state);
    const leftDetailedDocumentId = selectDetailedDocIdLeft(state);

    if (documents.length) {
        if ((documents[0] && documents[0].docflowDocumentData && documents[0].docflowDocumentData.statusId)
            || (documents[1] && documents[1].docflowDocumentData && documents[1].docflowDocumentData.statusId)
        ) {
            if ((documents[0] && documents[0].docflowDocumentData && documents[0].docflowDocumentData.statusId)
                && (documents[1] && documents[1].docflowDocumentData && documents[1].docflowDocumentData.statusId)) {
                docStatus = documents[0].docflowDocumentData.statusId;

            } else if ((documents[0] && documents[0].docflowDocumentData && documents[0].docflowDocumentData.statusId)) {
                docStatus = documents[0].docflowDocumentData.statusId;

            } else if ((documents[1] && documents[1].docflowDocumentData && documents[1].docflowDocumentData.statusId)) {
                docStatus = documents[1].docflowDocumentData.statusId;

            }

        } else if (
            (documents[0] && documents[0].packageStatusId === PackageStatuses.RECOGNIZED && documents[0].docId === null)
            || (documents[1] && documents[1].packageStatusId === PackageStatuses.RECOGNIZED && documents[1].docId === null)
        ) {
            docStatus = MonitoringStatuses.ACTS_NOT_RECOGNISED;
        } else if (
            (documents[0] && documents[0].packageStatusId === PackageStatuses.RECOGNIZED && documents[0].docId === null)
            || (documents[1] && documents[1].packageStatusId === PackageStatuses.RECOGNIZED && documents[1].docId === null)
        ) {
            docStatus = MonitoringStatuses.ACTS_NOT_RECOGNISED;
        }
    } else if (leftDetailedDocumentId === 'null' || leftDetailedDocumentId === undefined) {
        docStatus = MonitoringStatuses.ACTS_NOT_RECOGNISED;
    }

    return {
        isDetailedPage,
        docStatus,
        isLoggedIn: !(path === "/auth"),
        canGetBalance: !(path === ROUTES.Home) && !(path === ROUTES.Monitoring) ,
        login: login,
        activeAccountId,
        header: selectHeader(state),
        balance: !isNaN(parseInt(balance)) ? parseInt(balance) : 0,
        path
    };
};


const dispatchProps: MapDispatchToProps<NavigationBarDispatchProps, {}> = {
    onLogout: logout,
    getBalance,
    setUploadPopupOpen,
    onGetDifferenceProtocol: getDifferenceProtocol,
    onDownloadPdf: downloadPdf
};

export const NavigationBarConnected = connect(
    mapStateToProps,
    dispatchProps,
)(NavigationBar);
