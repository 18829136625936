import "@babel/polyfill";

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// import objectFitImages from 'object-fit-images';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app/App';

// objectFitImages()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <App />
);
