import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {State} from "../../store/rootReducers";
import {
    IMonitoringPageDispatchProps,
    IMonitoringPageStateProps,
    IRecognitionActTable,
    MonitoringPage
} from "./MonitoringPage";
import {
    getSortType,
    getStoragePage,
    selectCheckboxShow,
    selectDocIds,
    selectListPage,
    selectMonitoringList,
    selectSearchOptions,
    selectSortType
} from "../../selectors/monitoring";
import {
    getActsForHandleRecognition,
    getDetailedDocuments,
    getImagesForModalPreview,
    getRecognitionActs,
    handleCreateDocsPair,
    MonitoringStatuses,
    PackageStatuses,
    setCheckboxShow,
    setListPage,
    setPreviewModalOpen,
    setSearchOptions,
    setSortType,
    updateSelectedDocIds
} from "../../ducks/montitoring";
import moment from "moment";
import {routeTo} from "../../ducks/baseCRUD";
import {IMonitoringItem} from "../../api/monitoringApi";
import {selectUploadPopupIsOpen} from "../../selectors/upload";
import {Checkbox} from "@mui/material";
import {getStoreUtils} from "../../store/utils";

const mapStateToProps: MapStateToProps<IMonitoringPageStateProps, {}, State> = state => {
    const props: IMonitoringPageStateProps = {
        list: [],
        isUploadPopupOpen: false,
        options: [],
        sort: "",
        listPage: 0,
        checkboxShow: false,
        docIds: [],
    };
    const searchOptions = selectSearchOptions(state);
    const listFromState: IMonitoringItem[] = selectMonitoringList(state);
    const isOpen = selectUploadPopupIsOpen(state);
    const storageSort = getSortType();
    const stateSort = selectSortType(state);
    const storagePage = getStoragePage();
    const statePage = selectListPage(state);
    const checkboxShow = selectCheckboxShow(state);
    const docIds = selectDocIds(state);

    const {dispatch} = getStoreUtils();

    let list: IRecognitionActTable[] = [];
    props.options = searchOptions;

    if (isOpen) {
        props.isUploadPopupOpen = isOpen;
    }

    try {
        if (listFromState.length > 0) {
            const listProcessed = checkboxShow ? listFromState.filter(elem => elem.docflowDocumentData && elem.docId && elem.packageStatusId === PackageStatuses.RECOGNIZED).filter(acts => acts.docflowDocumentData.statusId === MonitoringStatuses.WAITING_FOR_PAIR) : listFromState;
            list = listProcessed.map(elem => {
                try {
                    let docStatus = null;
                    const docId = elem.docId;
                    const packageStatusId = elem.packageStatusId;
                    if (packageStatusId === PackageStatuses.RECOGNITION) {
                        docStatus = null;
                    } else if (packageStatusId === PackageStatuses.RECOGNIZED && docId === null) {
                        docStatus = MonitoringStatuses.ACTS_NOT_RECOGNISED;
                    } else {
                        docStatus = elem.docflowDocumentData ? elem.docflowDocumentData.statusId : null
                    }
                    return {
                        id: elem.docId,
                        status: docStatus,
                        packageId: elem.packageId,
                        contrAgent: (elem.docflowDocumentData && elem.docflowDocumentData.extendData) ? elem.docflowDocumentData.extendData.contractor : "",
                        organisation: (elem.docflowDocumentData && elem.docflowDocumentData.extendData) ? elem.docflowDocumentData.extendData.organization : "",
                        comment: (elem.docflowDocumentData && elem.docflowDocumentData.extendData) ? elem.docflowDocumentData.comment : "",
                        upload_date: moment(elem.loadDate, "DD.MM.YYYY HH:mm:ss").format("DD.MM.YYYY HH:mm:ss"),
                        period: `${(elem.docflowDocumentData && elem.docflowDocumentData.extendData) && elem.docflowDocumentData.extendData.periodStart 
                            ? moment(elem.docflowDocumentData.extendData.periodStart).format("DD.MM.YYYY") : ""} - ${(elem.docflowDocumentData && elem.docflowDocumentData.extendData) && elem.docflowDocumentData.extendData.periodEnd 
                            ? moment(elem.docflowDocumentData.extendData.periodEnd).format("DD.MM.YYYY") : ''}`,
                        actionButton: <></>,
                        checkAction: <Checkbox
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                event.nativeEvent.stopImmediatePropagation();

                                if(docIds.includes(elem.docId)){
                                    dispatch(updateSelectedDocIds(docIds.filter(id => id !== elem.docId)));
                                } else {
                                    docIds.length < 1
                                        ?  dispatch(updateSelectedDocIds([elem.docId]))
                                        :  dispatch(updateSelectedDocIds([docIds[0], elem.docId]));
                                }
                            }}
                            checked={docIds.includes(elem.docId)}
                        />,

                    }
                } catch (e) {
                    return undefined;
                }
            }).filter(elem => elem);
        }
    } catch (e) {
        console.log("e", e)
    }

    props.sort = stateSort !== storageSort ? storageSort : stateSort;
    props.checkboxShow = checkboxShow;
    props.docIds = docIds;

    if (list.length > 0) {
        props.listPage = statePage !== storagePage ? storagePage : statePage;
        props.list = list;
    }
    return {...props}
};

const dispatchProps: MapDispatchToProps<IMonitoringPageDispatchProps, {}> = {
    getRecognitionActs,
    getDetailedDocuments,
    routeTo: (route, id?) => {
        return routeTo(route, id)
    },
    setSearchOptions,
    setSortType,
    setListPage,
    setCheckboxShow,
    getActsForHandleRecognition,
    setPreviewModalOpen,
    getImagesForModalPreview,
    handleCreateDocsPair,
};

export const MonitoringPageConnected = connect(
    mapStateToProps,
    dispatchProps,
)(MonitoringPage);
