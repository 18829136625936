import React from "react";
import {InfoPage} from "../Info/InfoPage";

export interface IAuthPageStateProps {
}

export interface IAuthPageDispatchProps {
}

type AuthProps = IAuthPageStateProps & IAuthPageDispatchProps;

export function AuthPage(props: AuthProps) {
    return <InfoPage/>
}