import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {Button, Checkbox, FormControlLabel, IconButton, Paper} from "@mui/material";
import {arrayMove, SortableContainer, SortableElement} from "react-sortable-hoc";

import MyDropzone from "../dropzone/dropzone";
import {IDetailedDocumentPage, IDetailedFile, IFileFromInput,} from "../../ducks/upload";
import {PopupElement} from "../../ducks/popup";
import {asyncEncodeImageFileAsURL, findNextIndex, truncate} from "../../helper/other";
import DropzoneFullScreen from "../dropzone/dropzoneFullScreen";
import {useDraggable} from "react-use-draggable-scroll";

import styles from './UploadPopup.module.scss';

export interface IUploadPopupStateProps {
    files: IFileFromInput[];
    detailedFile: IDetailedFile | undefined;
    pageCount: number;
    isLoading: boolean;
    showPreviewPages: boolean;
    selectedIds: string[];
    page: IDetailedDocumentPage | undefined;
    isOpen: boolean;
}

export interface IUploadPopupDispatchProps {
    setUploadPageCount: (count: number) => void;
    setSelectedIds: (ids: string[]) => void;
    setDetailedFilePages: (page: IDetailedDocumentPage | undefined) => void;
    setUploadIsLoading: (isLoading: boolean) => void;
    setDetailedUpload: (uploadFile: IDetailedFile | undefined) => void;
    setUploads: (uploads: IFileFromInput[], usePrevUploads?: boolean) => void;
    deleteUploads: (uploads: IFileFromInput[]) => void;
    uploadFiles: (uploads: IFileFromInput[], usePrevUploads?: boolean) => void;
    popupPush: (popup: PopupElement) => void;
    setShowPreviewPages: (value: boolean) => void;
    setUploadPopupOpen: (value: boolean) => void;
    sendPackageToRecognition: (isPairSearchSkip: boolean) => void;
    onClearUploads: () => void;
    getUploads: () => void;
}

interface ISortableProps {
    index: number;
    detailedFile: IDetailedFile | undefined;
    setDetailedUpload: (uploadFile: IDetailedFile | undefined) => void;
    files: IFileFromInput[];
    setUploadPageCount: (count: number) => void;
    setShowPreviewPages: (value: boolean) => void;
    setUploads: (uploads: IFileFromInput[], usePrevUploads?: boolean) => void;
    setUploadIsLoading: (isLoading: boolean) => void;
    setIsCrossClick: (value: boolean) => void;
    pageCount: number;
    showPreviewPages: boolean;
    deleteUploads: (uploads: IFileFromInput[]) => void;
}

interface ISortableElementProps extends ISortableProps {
    elem: IFileFromInput;
    file: IFileFromInput;
    filePreviewIndex: number;
}

interface ISortableContainerProps extends ISortableProps {
    files: IFileFromInput[];
    heightGrid: number;
}

const SortableList = SortableContainer((props: ISortableContainerProps) => {
    const {files, heightGrid} = props;
    return (
        <div className={`${styles.inputDocuments} ${heightGrid}`}>
            {files.map((elem, index) => {
                return <SortableItem
                    {...props}
                    //@ts-ignore
                    elem={elem}
                    //@ts-ignore
                    filePreviewIndex={index}
                    key={`item-${index}`}
                    index={index}
                />
            })}
        </div>
    )
})

const SortableItem = SortableElement((props: ISortableElementProps) => {
    const {detailedFile, elem, filePreviewIndex} = props;
    const index = filePreviewIndex;
    const file = elem
    return elem ? <div
        style={{
            zIndex: 9000,
        }}
        key={index}
        className={`${styles.inputDocumentsItem} ${(detailedFile && (detailedFile.id === elem.id)) && styles.selectedItem}`}
        title={file.fileName}
    >
        <div
            className={styles.closeRow}
        >

            <div
                onMouseDown={(event) => {
                    if (event && event.type === 'mousedown' && event.button !== 0) {
                        return;
                    }
                    props.setIsCrossClick(true)
                    const deleteItem = () => {
                        try {
                            let newFiles = [...props.files];
                            newFiles.splice(index, 1);
                            const isDetailed = (detailedFile && (detailedFile.id === file.id));
                            if (isDetailed) {
                                const findPageIndex = findNextIndex(index, newFiles);
                                if (findPageIndex !== undefined) {
                                    const findFileForPreview = newFiles[findPageIndex];
                                    props.setDetailedUpload({
                                        ...findFileForPreview,
                                        index: findPageIndex,
                                    });
                                }
                            }
                            event.preventDefault();
                            setTimeout(() => {
                                props.deleteUploads([...newFiles]);
                                props.setIsCrossClick(false)
                                if (newFiles.length === 0) {
                                    props.setDetailedUpload(undefined);
                                    props.setUploads([]);
                                }
                            }, 50)
                        } catch {

                        }
                    }
                    if (event.type === 'touchend') {
                        deleteItem();
                    } else {
                        deleteItem();
                    }
                }}
                onClick={(e) => {

                }}
                className={styles.icon}
            />
        </div>
        <div className={styles.documentContainer}>
            <div
                className={styles.filePreview}
            >
                <img
                    draggable={false}
                    style={{
                        objectFit: "contain"
                    }}
                    width={130}
                    height={180}
                    src={`${file.fileUrl}`}
                    alt={""}
                    loading="lazy"
                />
            </div>
        </div>
        <div className={styles.inputDocumentsItemDescription}>
            {truncate(file.fileName, 25)}
        </div>
    </div> : <></>
})

export type UploadPopupProps = IUploadPopupStateProps & IUploadPopupDispatchProps;

export function DetailedFileLayer(props: UploadPopupProps) {
    const {detailedFile} = props;
    const refContainer =
        useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
    const ref =
        useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
    const {events} = useDraggable(ref ? ref : null, {
        activeMouseButton: 'Left',
    });

    useLayoutEffect(() => {
        if (refContainer && refContainer.current) {
            refContainer.current.addEventListener('mousewheel', preventScroll, {passive: false});
            refContainer.current.addEventListener('wheel', preventScroll, {passive: false});

            return () => {
                if (refContainer && refContainer.current) {
                    refContainer.current.removeEventListener('mousewheel', preventScroll);
                    refContainer.current.removeEventListener('wheel', preventScroll);
                }
            }
        }
    }, [])

    let rotate;
    let inverted;

    if (props.page) {
        switch (props.page.rotate) {
            case 90:
                inverted = styles.rotateHorizontal
                rotate = styles.rotate90;
                break;
            case 180:
                inverted = undefined;
                rotate = styles.rotate180;
                break;
            case 270:
                inverted = styles.rotateHorizontal
                rotate = styles.rotate270;
                break;
            case -90:
                inverted = styles.rotateHorizontal
                rotate = styles.rotateMinus90;
                break;
            case -180:
                inverted = undefined;
                rotate = styles.rotateMinus180;
                break;
            case -270:
                rotate = styles.rotateMinus270;
                inverted = styles.rotateHorizontal
                break;
            default:
                inverted = undefined;
                rotate = undefined;
                break;
        }
    }

    const onButtonSetZoom = (count: number,) => {
        const prevPage = props.page;
        prevPage.scale += count;
        props.setDetailedFilePages(prevPage)
    }
    const onWheel = (event) => {
        event.stopPropagation();

        event.nativeEvent.stopImmediatePropagation();
        //@ts-ignore
        let delta = event.deltaY || event.detail || event.wheelDelta;
        let newValue;
        if (delta < 0) {
            const prevPage = props.page;
            prevPage.scale += 0.2;
            props.setDetailedFilePages(prevPage)
        } else {
            const prevPage = props.page;
            prevPage.scale += -0.2;
            props.setDetailedFilePages(prevPage)
        }
    }
    const onButtonReset = () => {
        const prevPage = props.page;
        prevPage.scale = 1;
        props.setDetailedFilePages(prevPage)
    }
    const onButtonSetRotate = (count: number) => {
        const prevPage = props.page;
        prevPage.rotate += count;
        if (prevPage.rotate >= 360 || prevPage.rotate <= -360) {
            prevPage.rotate = 0;
        }
        props.setDetailedFilePages(prevPage)
    }
    const onButtonSetPage = (count: number) => {
        let index = props.detailedFile.index;
        index += count;
        if (index <= 0) {
            index = 0;
        } else if (index >= props.files.length - 1) {
            index = props.files.length - 1;
        }
        const file = props.files[index];
        props.setDetailedUpload({
            ...file,
            index: index,
        })
    }

    function preventScroll(e) {
        e.preventDefault();
        return false;
    }

    return <div
        draggable={false}
        className={`${styles.pdfFilesContainerPaper} `}
    >
        <div draggable={false} className={styles.pdfFilesContainer}>
            <div
                draggable={false}
                className={styles.pdfFilesContainer}
            >
                <Paper
                    ref={refContainer}
                    draggable={false}
                    elevation={0}
                    id={'pdfDoc'}
                    className={`${styles.pdfFile}`}
                >
                    <div
                        ref={ref}
                        draggable={false}
                        className={`${styles.pageContainer} ${rotate} ${inverted && styles.invertedPageContainer}`}
                        {...events}
                        onWheel={onWheel}
                    >
                        <img
                            style={{
                                objectFit: "contain"
                            }}
                            draggable={false}
                            src={detailedFile.fileUrl}
                            width={490 * props.page.scale}
                            height={590 * props.page.scale}
                        />
                    </div>
                    <div className={styles.documentButtonsContainer}>
                        <div className={styles.top}>
                                             <span className={styles.fileName}>
                                            {props.detailedFile.fileName}
                                        </span>
                        </div>
                        <div className={styles.bottom}>
                            <div className={styles.documentButtonsContainerLeft}>
                                <IconButton
                                    className={styles.scaleIcon}
                                    onClick={() => onButtonReset()}
                                />
                                <IconButton
                                    onClick={() => onButtonSetZoom(-0.1)}
                                    className={styles.zoomOutIcon}
                                />
                                <IconButton
                                    onClick={() => onButtonSetZoom(0.1)}
                                    className={styles.zoomInIcon}
                                />
                                <IconButton
                                    onClick={() => onButtonSetRotate(-90)}
                                    className={styles.rotateLeftIcon}
                                />
                                <IconButton
                                    onClick={() => onButtonSetRotate(90)}
                                    className={styles.rotateRightIcon}
                                />
                            </div>

                            <div
                                className={styles.documentButtonsContainerRight}
                            >
                                <IconButton
                                    onClick={() => onButtonSetPage(-1)}
                                    className={styles.arrowLeft}
                                />
                                <span
                                    className={styles.pageNumberTitle}
                                >
                                                {detailedFile.index + 1}
                                                </span>
                                <IconButton
                                    onClick={() => onButtonSetPage(+1)}
                                    className={styles.arrowRight}
                                />
                            </div>
                        </div>
                    </div>
                </Paper>
            </div>
        </div>
    </div>
}

export function UploadPopup(props: UploadPopupProps) {
    const inputRef = useRef();
    useEffect(() => {
        props.getUploads();
    }, [])
    useEffect(() => {
        const {files, detailedFile} = props;
        try {
            if (detailedFile === undefined && files.length > 0) {
                const file = files[0]
                props.setDetailedUpload({
                    ...file,
                    index: 0,
                })

            }
        } catch {

        }
    }, [props.files])
    const [isCrossClick, setIsCrossClick] = useState(false);
    const [isDropHidden, setIsDropHidden] = useState(true);
    const [checked, setChecked] = useState(false);
    const {detailedFile, files, setUploads} = props;

    function onChange(state) {
        props.setShowPreviewPages(false);
        const nextState = props.files.length !== 0 ? arrayMove(props.files, state.oldIndex, state.newIndex) : [];
        props.setUploads([...nextState]);
    }

    const handleFileDrop = async (acceptedFiles) => {
        setIsDropHidden(true);
        let newFiles = [];
        for (let i = 0; i < acceptedFiles.length; i++) {
            const file = acceptedFiles[i];
            await asyncEncodeImageFileAsURL(file)
                .then(async (base64res: any) => {
                    newFiles.push({
                        inputIndex: i,
                        id: `${new Date().getTime()}${i}`,
                        fileUrl: base64res,
                        fileName: file.name,
                        fileSize: file.size,
                    })
                });
        }
        props.uploadFiles([...newFiles].filter(elem => {
            const extension = elem.fileName.split('.').pop();
            return ['jpg', 'jpeg', 'png', 'pdf', 'xls', 'xlsx'].includes(extension)
        }), true)
    }

    return props.isOpen ? <div
        onDragEnter={(e) => {
            setIsDropHidden(false);
        }}
        className={styles.popupBackground}>
        {!isDropHidden && <DropzoneFullScreen
            files={[...props.files]}
            setFiles={props.setUploads}
            onDropHandler={handleFileDrop}
            onMouseLeaveEvent={() => {
                setIsDropHidden(true);
            }}
            onMouseOnEvent={() => {
                setIsDropHidden(false);
            }}
        />}
        <div
            className={`${styles.popupContainer} ${props.files.length === 0 ? styles.popupContainerEmptyDocList : styles.popupContainerNotEmptyDocList} ${props.files.length !== 0 && styles.backgroundNotEmpty}`}>
            <IconButton
                onClick={() => {
                    props.setUploadPopupOpen(false)
                }}
                className={styles.closeIcon}
            >
            </IconButton>
            {props.files.length === 0 && <div className={`${styles.uploadContainer}`}>
                <MyDropzone
                    isEmpty={[...props.files].length === 0}
                    files={[...props.files]}
                    setFiles={props.setUploads}
                    onDropHandler={handleFileDrop}
                />
            </div>}
            {props.files.length !== 0 &&
            <div className={styles.uploadContainerNotEmpty}>
                <div className={styles.leftPart}>
                    <div className={styles.topPart}>
                        <div className={styles.sortListContainer} id={'sortable-top-part'}>
                            <SortableList
                                helperContainer={document.getElementById('sortable-top-part')}
                                axis={'xy'}
                                {...props}
                                //@ts-ignore
                                setIsCrossClick={(value) => {
                                    setIsCrossClick((preValue) => value)
                                }}
                                //@ts-ignore
                                heightGrid={files.length <= 8 ? styles.gridHeightSmall : styles.gridHeight}
                                onSortEnd={(sort, event) => {
                                    if (sort.oldIndex === sort.newIndex && !isCrossClick) {
                                        const filePreview = props.files[sort.newIndex];
                                        if (filePreview) {
                                            const file = props.files[sort.newIndex];
                                            props.setDetailedUpload({
                                                ...file,
                                                index: sort.newIndex,
                                            });
                                        }
                                    } else {
                                        onChange(sort);
                                    }

                                }}
                            />
                        </div>
                        <div style={{display: 'none'}} className={styles.dropzoneUploadContainer}>
                            <input
                                multiple={true}
                                maxLength={255}
                                hidden={true}
                                type="file"
                                accept="image/jpeg,image/png,application/pdf,image/tiff,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                ref={inputRef}
                                value={''}
                                onChange={(event) => {
                                    const files = event.target.files;
                                    const newFiles = [];
                                    for (let i = 0; i < files.length; i++) {
                                        newFiles.push(files[i]);
                                    }
                                    handleFileDrop(newFiles);
                                }}
                            />
                        </div>
                    </div>
                    <FormControlLabel control={<Checkbox
                        disabled={props.files.length < 2}
                        sx={{
                            color: `#F57B20`,
                            '&.Mui-checked': {
                                color: `#F57B20`,
                            },
                            fontSize: '14px',
                        }}
                        checked={checked}
                        onChange={((event, checked1) => setChecked(checked1))}
                    />} label={<span className={styles.label}>Сравнить сразу</span>}/>
                    <div className={styles.buttonsContainer}>
                        <Button
                            variant={'contained'}
                            onClick={() => {
                                //@ts-ignore
                                inputRef.current.click()
                            }}
                            className={styles.uploadButton}
                        >
                            Загрузить еще
                        </Button>
                        <Button
                            variant={'contained'}
                            disabled={!files.length}
                            onClick={() => {
                                props.popupPush({
                                    actionTitle: "Удалить",
                                    actionVisible: true,
                                    data: ["Вы действительно хотите удалить все загруженные страницы?"],
                                    actionHandler: () => {
                                        setUploads([]);
                                        props.setDetailedUpload(undefined);
                                        props.onClearUploads();
                                    },
                                    cancelVisible: true,
                                });
                            }}
                            className={styles.cancelButton}
                        >
                            Очистить
                        </Button>
                        <Button
                            variant={'contained'}
                            onClick={() => {
                                props.sendPackageToRecognition(checked && props.files.length >= 2);
                            }}
                            disabled={!files.length}
                            className={styles.uploadButton}
                        >
                            Отправить на обработку
                        </Button>
                    </div>
                </div>
                <div className={styles.rightPart}>
                    {props.detailedFile && <DetailedFileLayer
                        {...props}
                    />}
                </div>
            </div>
            }
        </div>
    </div> : <></>
}
