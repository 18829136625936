//@ts-ignore
import wretch, {Wretch} from "wretch";
import {getStoreUtils} from "../store/utils";
import {getToken} from "../helper/other";
import {push} from "connected-react-router";
import {ROUTES} from "../app/Routes";

export const AUTHORIZATION_HEADER = 'X-AUTH';
export const AUTHORIZATION_PREFIX = 'Bearer ';

export const ENDPOINTS = {
    auth: "/user/auth",
    users: "/user",
    balance: "/balance"
}

export const EXTERNAL_TOKEN_AUTH = 'token'

async function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const getBase = (): Wretch => {
    const token = getToken(EXTERNAL_TOKEN_AUTH);
    const headers = {"Recognition-Product": 'Reconciliation',}

    //@ts-ignore
    if(window.REACT_APP_SPREAD_HEADER_VALUE && window.REACT_APP_SPREAD_HEADER_NAME){
        //@ts-ignore
        headers[window.REACT_APP_SPREAD_HEADER_NAME] = window.REACT_APP_SPREAD_HEADER_NAME;
    }
    if (process.env.REACT_APP_TEST_TOKEN) {
        headers[AUTHORIZATION_HEADER] = `${AUTHORIZATION_PREFIX}${process.env.REACT_APP_TEST_TOKEN}`
    }
    //@ts-ignore
    if (token && process.env.REACT_APP_EXTERNAL_TOKEN_AUTH) {
        headers[AUTHORIZATION_HEADER] = `${AUTHORIZATION_PREFIX}${token}`
    }
    return wretch(process.env.REACT_APP_BASE_API_URL)
        .headers(headers)
        .options({credentials: "include"})
        .catcher(401, (error, originalRequest) => {
            getStoreUtils().dispatch(push(ROUTES.Auth))
        })
        .catcher(403, async (error, originalRequest) => {
        });
};

export type UrlPart = string | number;

export const getEndpoint = (
    endpoint: string,
    urlPart?: UrlPart
) => {
    const url = "/api" + endpoint + (urlPart !== undefined ? `${urlPart}` : "");
    return getBase().url(url);
};

export const getTokenFromUrl = () => {
    const query = window.location.search.replace( '?', '');
    const items = query?.split('&')
    let token = items?.find(i => i.indexOf(EXTERNAL_TOKEN_AUTH) > -1)
    token = token?.replace(`${EXTERNAL_TOKEN_AUTH}=`, '')
    return token
}
