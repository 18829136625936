import React, {useLayoutEffect, useState} from "react";
import styles from "../../pages/DifferencePage/DifferencePage.module.scss";
import {IconButton, Paper} from "@mui/material";
import {getDifferenceName} from "../../selectors/other";
import PushPin from "@mui/icons-material/PushPin";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {IDetailedDocument, IDiscrepancyStatus,} from "../../api/monitoringApi";


export interface IDifferenceListStateProps {
    detailedDiff: string | undefined;
    detailedDocLeft: IDetailedDocument | undefined;
}

export interface IDifferenceListDispatchProps {
    setDetailedDiff: (diff: string | undefined) => void;
    setDifferenceStatus: (index: number, status: IDiscrepancyStatus) => void;
}

export type DifferenceListProps = IDifferenceListStateProps & IDifferenceListDispatchProps;

export function DifferenceList (props: DifferenceListProps) {
    const [favourite, setFavourite] = useState([]);
    const {detailedDiff, setDetailedDiff, setDifferenceStatus, detailedDocLeft,} = props;

    const onSetDetailedDiff = (diff: string | undefined) => {
        if (diff) {
            setDetailedDiff(diff);
        }
    }

    enum Differences {
        'StartBalanceDifference',
        'MissingRow',
        'DebitAndCreditDifference',
        'MissingContractor',
        'DatesDifference',
        'TurnoverDifference',
        'FinalBalanceDifference'
    }

    useLayoutEffect(() => {
        setTimeout(() => {
            if (detailedDiff) {
                setTimeout(() => {
                    const discrepancyGroup = document.getElementsByClassName( `${styles.commentsContainerItem} ${styles.selectedItem}`)?.item(0)
                    discrepancyGroup?.scrollIntoView({inline: 'center', block: 'center'})
                }, 50)
            }
        }, 25)
    }, [detailedDiff]);

    return  <div className={`${styles.subContainer} ${styles.withoutBackground}`}>
        {detailedDocLeft && detailedDocLeft.docflowDocumentData.extendData.discrepancyData.sort((diff1, diff2) => {
            return Differences[diff1.type] > Differences[diff2.type] ? 1 : Differences[diff1.type] < Differences[diff2.type] ? -1 : 0
        }).map((diff, topIndex) => {
            const diffID = diff.details ? `${diff.apiIndex}` : "";
            const isPinned = diff.status.length === 1 && diff.status[0].pinned;
            const isAccepted = diff.status.length === 1 && diff.status[0].accept;
            return <Paper
                key={`${getDifferenceName(diff.type)}${topIndex}`}
                elevation={0}
                className={`${styles.commentsContainerItem} ${isAccepted && styles.commentsContainerItemCollapsed} ${(detailedDiff === diffID) && styles.selectedItem} ${isPinned && styles.commentsContainerItemWrapperItemFavourite}  ${diff.type === 'StartBalanceDifference' ? styles.commentsContainerItemHigh : styles.commentsContainerItemLow}`}
                onClick={() => {
                    onSetDetailedDiff(diffID);
                }}
            >
                <div className={styles.commentsContainerItemWrapper}>
                    <div
                        className={`${styles.commentsContainerItemWrapperItem}`}>
                        <div className={styles.text}>{getDifferenceName(diff.type)}</div>
                    </div>
                </div>
                <div className={styles.commentsContainerItemWrapper}>
                    <div className={styles.commentsContainerItemWrapperItemClose}>
                        <IconButton
                            className = {styles.iconButton}
                            sx={{
                                cursor: 'pointer'
                            }}
                            size={"small"}
                            title={`${favourite.includes(`${topIndex}-`) ? "Открепить" : "Закрепить"}`}
                            onClick={(event) => {
                                event.stopPropagation();
                                if (favourite.includes(`${topIndex}-`)) {
                                    setFavourite(favourite.filter(fV => (fV !== `${topIndex}-`)))
                                } else {
                                    setFavourite(favourite.concat(`${topIndex}-`))
                                }
                            }}>
                            {isPinned ?
                                <PushPin
                                    onClick={() => {
                                        setDifferenceStatus(topIndex, {
                                            accept: false,
                                            pinned: !isPinned
                                        })
                                    }}
                                    className={styles.pushpinIcon}
                                    fontSize="inherit"
                                /> :
                                <PushPinOutlinedIcon
                                    onClick={() => {
                                        setDifferenceStatus(topIndex, {
                                            accept: false,
                                            pinned: !isPinned
                                        })
                                    }}
                                    className={styles.pushpinIcon}
                                    fontSize="inherit"
                                />}
                        </IconButton>
                        <IconButton
                            className = {styles.iconButton}
                            sx={{
                                cursor: 'pointer'
                            }}
                            title={`${isAccepted ? "Отменить принятие" : "Принять"}`}
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                event.nativeEvent.stopImmediatePropagation();
                                setDifferenceStatus(topIndex, {
                                    accept: !isAccepted,
                                    pinned: false
                                });
                            }}
                            size={"small"}
                        >
                            <CheckCircleOutlineIcon fontSize="inherit"/>
                        </IconButton>
                    </div>
                </div>
            </Paper>
        })}
    </div>
}
