import React, {useLayoutEffect} from 'react';
import {getStoreUtils} from "../../store/utils";
import {getAuthBalance} from "../../ducks/auth";
import {MonitoringPageConnected} from "../MonitoringPage/MonitoringPageConnected";
import {EXTERNAL_TOKEN_AUTH, getTokenFromUrl} from "../../api/endpoints";
import {setCookie} from "../../helper/other";
import {ROUTES} from "../../app/Routes";

export function FirstPage() {
    const isFirstLogin = window.location.href.includes('isOpen');
    const token = getTokenFromUrl()
    if (token) {
        setCookie(EXTERNAL_TOKEN_AUTH, token, 30)
        window.location.href = ROUTES.Home
    }
    useLayoutEffect(() => {

        if(isFirstLogin){
            getStoreUtils().dispatch(getAuthBalance())
        }
    }, [])
    return isFirstLogin ? <></> : <MonitoringPageConnected/>;
}
