import {State} from "../store/rootReducers";
import {ROUTES} from "../app/Routes";

export function selectHeader(state: State) {
    const path = state.router.location.pathname;
    let header = "";

    switch (path) {
        case ROUTES.Monitoring:
        case "/": {
            header = "Список актов сверки"
            break;
        }
        case "/auth": {
            header = "Вход"
            break;
        }
        case ROUTES.Diff: {
            header = "Сравнение актов сверки"
            break;
        }
        case ROUTES.Info: {
            header = "О сервисе"
            break;
        }
    }

    return header;
}

export function selectUrlId(state: State): string | number | undefined {
    const pathname = state.router.location.pathname;
    const elements = pathname.split('/');
    const rawId = elements[elements.length - 1];
    return rawId ? rawId : undefined;
}

export function selectPath(state: State, processed: boolean = true) {
    const pathname = state.router.location.pathname;
    const elements = pathname.split("/");

    let path;
    const lastElement = elements[elements.length - 1];
    if (processed && !isNaN(parseInt(lastElement))) { //is numeric
        elements.splice(elements.length - 1, 1);
        path = elements.join('/');
    } else if (processed && isNaN(parseInt(lastElement)) && lastElement.length === 36) {
        elements.splice(elements.length - 1, 1);
        path = elements.join('/');
    } else {
        path = pathname;
    }

    return path;
}

export function getDifferenceName(difference: string | undefined): string {
    let diffName = '';
    switch (difference) {
        case 'MissingRow':
            diffName = 'Отсутствует строка';
            break;
        case 'StartBalanceDifference':
            diffName = 'Отличается начальное сальдо';
            break;
        case 'FinalBalanceDifference':
            diffName = 'Отличается конечное сальдо';
            break;
        case 'TurnoverDifference':
            diffName = 'Отличается сумма оборотов';
            break;
        case 'DebitAndCreditDifference':
            diffName = 'Отличаются суммы';
            break;
        case 'MissingContractor':
            diffName = 'Отсутствует контрагент';
            break;
        case 'DatesDifference':
            diffName = 'Отличаются даты';
            break;
    }
    return diffName;
}
