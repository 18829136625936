import {getEndpoint} from "./endpoints";
import moment from "moment";
import {IImagesByPackageId} from "./uploadApi";

export interface IMonitoringFilter {
    sortBy: string;
    sort: string;
    extendData: boolean;
    filter: {
        isAllUnrecognized?: boolean;
        loadDateFrom?: string;
        loadDateTo?: string;
        status: any[];
        packageStatus?: number[]
        jsonContentText?: string
    }

}

export interface IMonitoringItem {
    docId: string,
    loadDate: string,
    packageId: number,
    packageStatusId: number,
    documentStatusId: number,
    title: string,
    user: string,
    comment: string,
    created: false,
    docflowDocumentData: {
        id: number,
        createdAt: string
        comment: string
        statusId: number;
        extendData: {
            organization: string,
            contractor: string,
            agreement: string,
            periodStart: string,
            periodEnd: string,
            comparedData: [],
            discrepancyData: IDiscrepancyData[]
        }
    },
    jsonContentPartner: string,
    jsonContentDocType: string,
    jsonContentDocDate: string,
    jsonContentDocNum: string,
    jsonContentDocOrg: string,
    jsonContentWithVAT: string,
    jsonContentWithoutVAT: string
}


export const defaultFilter: IMonitoringFilter = {
    sortBy: "load_date",
    sort: "DESC",
    extendData: true,
    filter: {
        loadDateFrom: moment().add(-1, "month").toDate().toISOString(),
        loadDateTo: moment(moment().format("YYYY-MM-DD 23:59:59")).toDate().toISOString(),
        status: [
            null, 11, 12, 13, 14, 15, 1
        ],
    }

}

export interface IDiscrepancyStatus {
    pinned: boolean;
    accept: boolean
}

export interface IDiscrepancyData {
    apiIndex: number;
    type: string;
    status: IDiscrepancyStatus[];
    details: IDifference[]
}

export interface IDifference {
    rowId: string;
    difference: number;
    apiIndex: number;
    documentPageIndex: number;
    documentId: string;
    location: IDifferenceLocation;
    discrepancyIndex?: number
}

export interface IDifferenceLocation {
    leftOffset: number;
    topOffset: number;
    width: number;
    height: number;
    bottomOffset: number;
    rightOffset: number
}

export interface IDetailedDocument {
    docId: string;
    loadDate: string;
    packageId: number;
    packageStatusId: number,
    docflowDocumentData: {
        id: number;
        createdAt: string;
        comment: string;
        pairDocumentId: string;
        statusId: number;
        extendData: {
            organization: string,
            contractor: string,
            agreement: string,
            periodStart: string,
            periodEnd: string,
            discrepancyData: IDiscrepancyData[]
        }
    }
    jsonContent: {
        fields: IDifference[]
    }
}

export interface IDocumentImageId {
    finalImgBase64: string
}

export interface IDocumentImage {
    fileUrl: string
}

export interface IUpdateDocument {
    status: number;
    comment: string,
    jsonContent?: string
}

export interface IUpdateDocumentList {
    status: number;
    comment: string,
    docId: string,
    discrepancy: IDiscrepancyData[],
    jsonContent?: string
}

export interface INotRecognizedPackage {
    docId: any,
    loadDate: any,
    packageId: number,
    user: string,
    created: boolean,
    docflowDocumentData: any,
    jsonContent: {
        id: any,
        docType: any,
        pages: {
            id: 237004
        },
        contractorStamp: any,
        customerStamp: any,
        registrationStamp: any,
        signatures: any,
        codes: any,
        fields: any,
        saveData: any
    }
}

export interface ITechReport {
    subject: string;
    message: string;
    packageId: number[]
}

export interface IHandlePairDocID {
    docId: string
}

class MonitoringApi {
    async getList(props: IMonitoringFilter) {
        return await getEndpoint("/docs",)
            .post({...props})
            .res();
    }

    async getDetailedDocumentByDocId(docId: string) {
        return await getEndpoint(`/doc/${docId}`,)
            .get()
            .res();
    }

    async getDocumentImage(docId: string | number) {
        return await getEndpoint(`/doc/img/${docId}`,)
            .get()
            .res();
    }

    async sendTechnicalReport(props: ITechReport) {
        return await getEndpoint(`/notification`,)
            .post(props)
            .res();
    }

    async updateDocumentStatus(props: IUpdateDocumentList[]) {
        return await getEndpoint(`/doc/list`,)
            .post(props)
            .res();
    }

    async getUnrecognizedPackage(packageId: number) {
        return await getEndpoint(`/package/${packageId}/unrecognized`,)
            .get()
            .res();
    }

    async handlePairDocuments(mainId: string, props: IHandlePairDocID) {
        return await getEndpoint(`/doc/${mainId}/pair`,)
            .put(props)
            .res();
    }

    async deleteUnrecognizedPackage(packageId: number, imagesId: IImagesByPackageId) {
        return await getEndpoint(`/packageid/${packageId}/unrecognized/image`,)
            .json(imagesId)
            .delete()
            .res();
    }

}

export function getMonitoringApiObj() {
    return new MonitoringApi();
}
