import {push} from "connected-react-router";

export const routeTo = (route: string | null, id?: number) => {
    if (route === null) {
        return () => {
        };
    } else {
        const idSubRoute = (id) ? "/" + `${id}` : "";
        return push(`${route}` + idSubRoute);
    }
}
