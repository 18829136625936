import {Button, styled} from '@mui/material';

export const CorrectActionButton = styled(Button)`
  width: 250px;
  height: 50px;
  color: #ffffff;
  font-size: 18px;
  font-family: 'Open Sans',Arial,sans-serif;
  line-height: 1.55;
  font-weight: 600;
  border-radius: 5px;
  background-image: linear-gradient(180deg, rgba(254,140,0,1) 0%, rgba(248,54,0,1) 100%);
  border-color: transparent;
  border-style: solid;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  box-shadow: 3px 5px 10px 1px rgba(0,0,0,0.3);
`;