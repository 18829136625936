import React, {useCallback} from "react";
import styles from "./dropzoneFullScreen.module.scss";
import {useDropzone} from "react-dropzone";
import {IFileFromInput} from "../../ducks/upload";

export interface IDropzoneFullScreenProps {
    files: IFileFromInput[];
    setFiles: (files: IFileFromInput[]) => void;
    onDropHandler: (params: any) => void;
    onMouseLeaveEvent: () => void;
    onMouseOnEvent: () => void;
}

export default function DropzoneFullScreen(props: IDropzoneFullScreenProps) {
    const onDrop = useCallback(props.onDropHandler, [])
    const {getRootProps, getInputProps} = useDropzone({onDrop, noClick: true})
    return (
        <div
            className={styles.popupBack}
        >
            <div {...getRootProps({
                accept: "image/jpeg,image/png,application/pdf,image/tiff"
            })}
                 onDragLeave={() => {
                     setTimeout(() => {
                         props.onMouseLeaveEvent();
                     }, 0)
                 }} className={styles.dropzoneContainerFull}/>
            <div
                onDragEnter={() => {
                    props.onMouseOnEvent();
                }}
                className={styles.popupContainer}
            >
                <div className={styles.dropZone}>
                    <input
                        {...getInputProps({
                            accept: "image/jpeg,image/png,application/pdf,image/tiff"
                        })}
                    />
                    <div className={styles.dropZoneTitle}>
                        ПЕРЕМЕСТИТЕ ФАЙЛЫ СЮДА
                    </div>
                </div>
            </div>
        </div>
    )
}
