import styles from './MonitoringPage.module.scss';
import React, {FC, useEffect, useMemo, useRef, useState} from "react";
import {ITableProps, Table} from "../../components/table/Table";
import PageWrapper from "../../components/pageWrapper/PageWeapper";
import {ROUTES} from "../../app/Routes";
import moment from "moment";
import {getLocaleStorageItem, setLocaleStorageItem, tableCustomDateSort, truncate} from "../../helper/other";
import "moment/locale/ru";
import {getDetailedDocuments, MonitoringStatuses, setSortType, SortDirections} from "../../ducks/montitoring";
import {Box} from "@material-ui/core";
import {
    Autocomplete,
    Button,
    Icon,
    IconButton,
    InputAdornment,
    ListItemIcon,
    MenuItem,
    Select,
    Stack,
    TextField,
    ThemeProvider
} from "@mui/material";
import {themeSelect} from "../../muiThemes";
import {styled} from "@mui/styles";
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import processing from "./processing.svg";
import processError from "../../processError.svg";
import twoDocOkStatus from "../../twoDocOkStatus.svg";
import twoDocsErrorStatus from "../../twoDocsErrorStatus.svg";
import waitingForPair from "../../waitingForPair.svg";
import processFinishOkStatus from "../../processFinishokStatus.svg";
import processFinishErrorStatus from "../../processFinishErrorStatus.svg";
import deletedDoc from "./deletedDoc.svg";
import CloseIcon from "@mui/icons-material/Close";
import 'rsuite/styles/index.less';
import "./calendarCSS.css";
import {defaultFilter, IMonitoringFilter} from "../../api/monitoringApi";
import {getStoreUtils} from "../../store/utils";
import {Column} from "material-table";
import {getSortType, setStorageSortType} from "../../selectors/monitoring";
import "react-datepicker/dist/react-datepicker.css";
import {DateRangePicker as RangeDatePicker} from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import calendarIcon from "./calendarIcon.svg";
import {useWindowDimensions} from "../../hooks/use-windows-dimenssions";
export interface IRecognitionActTable {
    id: string;
    contrAgent: string;
    organisation: string;
    period: string;
    upload_date: string;
    comment: string;
    status: number,
    packageId: number,
    checkAction: JSX.Element;
    actionButton: JSX.Element;
}

export interface IFilterOption {
    value: number;
    label: string;
    icon: string;
    isHidden?: boolean;
}

export const FILTER_OPTIONS: IFilterOption[] = [
    {
        value: null,
        label: "Распознавание",
        icon: processing,
    },
    {
        value: 1,
        label: "Акт не распознан",
        icon: processError,
    },
    {
        value: 12,
        label: "Акты сопоставлены",
        icon: twoDocOkStatus,
    },
    {
        value: 13,
        label: "Обнаружены расхождения",
        icon: twoDocsErrorStatus,
    },
    {
        value: 11,
        label: "Ожидание ответного акта",
        icon: waitingForPair,
    },
    {
        value: 14,
        label: "Акт принят",
        icon: processFinishOkStatus,
    },
    {
        value: 15,
        label: "Акт отклонен",
        icon: processFinishErrorStatus,
    },
    {
        value: 8,
        label: "Удален",
        icon: deletedDoc,
    },
];

export interface IMonitoringPageStateProps {
    list: IRecognitionActTable[];
    isUploadPopupOpen: boolean;
    options: string[];
    sort: string;
    listPage: number;
    checkboxShow: boolean;
    docIds: string[];
}

export interface IMonitoringPageDispatchProps {
    getRecognitionActs: (filter?: IMonitoringFilter, isSearch?: boolean) => void;
    getDetailedDocuments: (docId: string) => void;
    getImagesForModalPreview: (docId: string) => void;
    routeTo: (route: string | null, id?: number) => void;
    setSortType: (sort: SortDirections | undefined) => void;
    setSearchOptions: (options: string[]) => void;
    handleCreateDocsPair: (docIds: string[]) => void;
    setListPage: (pageNumber: number) => void;
    setCheckboxShow: (flag: boolean) => void;
    getActsForHandleRecognition: () => void;
    setPreviewModalOpen: (flag: boolean) => void;
}

export interface IMonitoringPageState {
    dateFrom: string;
    dateTo: string;
    statusesFiltered: IFilterOption[];
    statuses: IFilterOption[];
    search: string;
    status: number;
    timeout: any;
}

export type MonitoringPageProps = IMonitoringPageStateProps & IMonitoringPageDispatchProps;

let interval = undefined;

const initialState: IMonitoringPageState = {
    dateFrom: moment().add(-1, "y").format('YYYY-MM-DD HH:mm:ss'),
    dateTo: moment().format("YYYY-MM-DD 23:59:59"),
    statuses: [...FILTER_OPTIONS],
    statusesFiltered: [...FILTER_OPTIONS.filter(elem => ![MonitoringStatuses.ACTS_PASSED, MonitoringStatuses.ACTS_REJECTED, MonitoringStatuses.DELETED].includes(elem.value))],
    search: "",
    status: 0,
    timeout: undefined,
}

const CustomSelect = styled(Select)(() => ({
    "&.MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "transparent"
        },
        "&:hover fieldset": {
            borderColor: "transparent"
        },
        "&.Mui-focused fieldset": {
            borderColor: "transparent"
        }
    }
}));

export const MonitoringPage: FC<MonitoringPageProps> = (props) => {
    let stateFromStorage: IMonitoringPageState = undefined;
    const tableContainerRef = useRef<HTMLDivElement | null>(null);
    useWindowDimensions();
    try {
        //@ts-ignore
        stateFromStorage = JSON.parse(getLocaleStorageItem("stateFromStorage"));
    } catch (e) {
        console.log("e", e, stateFromStorage)
    }
    const [state, setState] = useState(stateFromStorage ? {
        ...stateFromStorage, dateFrom: moment().add(-1, "y").format("YYYY-MM-DD HH:mm:ss"),
        dateTo: moment(moment().format("YYYY-MM-DD 23:59:59")).format('YYYY-MM-DD HH:mm:ss')
    } : {...initialState});
    const data = (props.sort === SortDirections.DESC || !props.sort) ? props.list.sort((a, b) => tableCustomDateSort(
        b.upload_date,
        a.upload_date,
        "DD.MM.YYYY HH:mm:ss",
    )) : props.list.sort((a, b) => tableCustomDateSort(
        a.upload_date,
        b.upload_date,
        "DD.MM.YYYY HH:mm:ss",
    ))
    const {docIds, handleCreateDocsPair, checkboxShow} = props;
    const defaultTableColumns = useMemo((): Column<Object>[] => {
        return [
            props.checkboxShow && {
                title: '',
                field: 'checkAction',
                sorting: false,
            },
            {
                title: 'СТАТУС',
                field: 'status',
                sorting: false,
                render: rowData => {
                    const {status} = rowData as IRecognitionActTable;
                    const findStatus = FILTER_OPTIONS.find(elem => elem.value === status);
                    return <div
                        onClick={() => {
                        }}
                        className={styles.iconRow} title={findStatus ? findStatus.label : ''}>
                        <ListItemIcon sx={{width: "35px", height: "35px"}}>
                            <Icon sx={{width: "35px", height: "35px"}}>
                                <img alt={""} src={findStatus ? findStatus.icon : twoDocsErrorStatus} width={35}
                                     height={35}/>
                            </Icon>
                        </ListItemIcon>
                    </div>;
                }
            },
            {
                title: 'ДАТА ЗАГРУЗКИ',
                field: 'upload_date',
                sorting: true,
                customSort: () => {
                    const item = getSortType();
                    if (interval) {
                        clearTimeout(interval)
                    }
                    interval = setTimeout(() => {
                        if (item === SortDirections.ASC) {
                            setStorageSortType(SortDirections.DESC)
                            getStoreUtils().dispatch(setSortType(SortDirections.DESC));
                        } else if (item === SortDirections.DESC || !item) {
                            setStorageSortType(SortDirections.ASC)
                            getStoreUtils().dispatch(setSortType(SortDirections.ASC));
                        }
                    }, 0)

                    return 1;
                }
            },
            {title: 'КОНТРАГЕНТ', sorting: false, field: 'contrAgent',},
            {title: 'ОРГАНИЗАЦИЯ', sorting: false, field: 'organisation'},
            {title: 'ПЕРИОД', sorting: false, field: 'period',},
            {
                title: 'КОММЕНТАРИЙ', sorting: false, field: 'comment',
                render: rowData => {
                    const {comment} = rowData as IRecognitionActTable;
                    return <div
                        title={comment}
                    >
                        {truncate(comment, 40)}
                    </div>;
                }
            },
        ].filter(elem => elem)
    }, [
        interval,
        props.checkboxShow,
        docIds,
    ])

    const tableProps: ITableProps = {
        checkboxShow: props.checkboxShow,
        docIds: [],
        pageSize: 10,
        onChangeListPage: (pageNumber => {
            props.setListPage(pageNumber)
        }),
        initialPage: props.listPage,
        data: [...data],
        //@ts-ignore
        columns: defaultTableColumns,
        onRowClick: (event, rowData) => {
            const {id, packageId} = rowData;
            if (props.checkboxShow) {
                props.setPreviewModalOpen(true);
                props.getImagesForModalPreview(id);
            } else {
                if (![
                    MonitoringStatuses.DELETED,
                    MonitoringStatuses.RECOGNITION,
                ].includes(rowData.status)) {
                    if (rowData.status === MonitoringStatuses.ACTS_NOT_RECOGNISED) {
                        props.routeTo(`${ROUTES.Diff}/?packageId=${packageId}`);
                    } else {
                        props.routeTo(`${ROUTES.Diff}/${id}`);
                        getStoreUtils().dispatch(getDetailedDocuments(id))
                    }
                }
            }
        }
    }


    useEffect(() => {
        props.getRecognitionActs({
            sort: defaultFilter.sort,
            sortBy: defaultFilter.sortBy,
            extendData: true,
            filter: {
                status: state.statusesFiltered.map(elem => elem.value),
                loadDateFrom: moment(state.dateFrom).toDate().toISOString(),
                loadDateTo: moment(state.dateTo).toDate().toISOString(),
                jsonContentText: state.search,
            }
        });
    }, [])

    useEffect(() => {
        if (!props.isUploadPopupOpen) {
            props.getRecognitionActs({
                sort: defaultFilter.sort,
                sortBy: defaultFilter.sortBy,
                extendData: true,
                filter: {
                    status: state.statusesFiltered.map(elem => elem.value),
                    loadDateFrom: moment(state.dateFrom).toDate().toISOString(),
                    loadDateTo: moment(state.dateTo).toDate().toISOString(),
                    jsonContentText: state.search,
                }
            });
        }
    }, [state.statusesFiltered.length, state.dateTo, state.dateFrom, props.checkboxShow]);
    //@ts-ignore
    const options = [...new Set(props.options.filter(elem => elem))].map((option, index) => ({
        label: option,
        key: `${index}1-option}`
    }))

    const mapStateToStorage = () => {
        let stateForStorage = {...state}
        delete stateForStorage.dateTo
        delete stateForStorage.dateFrom
        return stateForStorage
    }

    //@ts-ignore
    const isForIE = window.REACT_APP_INTERNET_EXPLORER || process.env.REACT_APP_INTERNET_EXPLORER === 'true';
    const pickerFormat = isForIE ? "YYYY-MM-DD" : "DD.MM.YYYY";

    return <PageWrapper>
        <div className={styles.monitoringPageContainer}>
            <div className={styles.filtersContainer}>
                <div className={styles.topPart}>
                    <div className={styles.leftPart}>
                        <ThemeProvider theme={themeSelect}>
                            <CustomSelect className={styles.customSelect}
                                          onChange={(event) => {
                                              const value = event.target.value;
                                              const findElem = state.statuses.find(elem => elem.value === value);
                                              const findIndex = state.statuses.findIndex(elem => elem.value === value);
                                              const newStatuses = [...state.statuses];
                                              newStatuses[findIndex].isHidden = true;
                                              setLocaleStorageItem("stateFromStorage", {
                                                  ...mapStateToStorage(),
                                                  statuses: newStatuses,
                                                  statusesFiltered: [...state.statusesFiltered, findElem],
                                              }, 10);

                                setState((prevState) => ({
                                    ...prevState,
                                    statuses: newStatuses,
                                    statusesFiltered: [...prevState.statusesFiltered, findElem],
                                }))
                            }}
                            value={99999}
                            variant={"outlined"}
                            size={"small"}
                            sx={{
                                width: "90px",
                                border: "none",
                            }}
                        >
                            <MenuItem style={{display: "none"}} value={99999}>
                                Статус
                            </MenuItem>
                            <div className={styles.statuses}>
                                {state.statuses.map((elem, index) => (
                                    !state.statusesFiltered.map(elem => elem.value).includes(elem.value) ? <div className={styles.menuItem}><MenuItem
                                        key={elem.value + index}
                                        value={elem.value}
                                    >
                                        <div className={styles.filterElement}>
                                            <ListItemIcon sx={{width: "40px", height: "40px"}} className={styles.listItemIcon}>
                                                <Icon sx={{width: "40px", height: "40px"}}>
                                                    <img alt={""} src={elem.icon} width={35} height={35}/>
                                                </Icon>
                                            </ListItemIcon>
                                            <div>
                                                {elem.label}
                                            </div>
                                        </div>
                                    </MenuItem></div> : <></>))}
                            </div>
                        </CustomSelect>
                    </ThemeProvider>
                    <div className={styles.filterElement}>
                        {!isForIE && <DateRangePicker
                            format={'dd.MM.y'}
                            calendarIcon={<img src={calendarIcon} alt={""} width={20} height={20}/>}
                            calendarClassName={styles.calendar}
                            onChange={(event) => {
                                setState((prevState) => {
                                    return {
                                        ...prevState,
                                        dateFrom: moment(event[0]).format('YYYY-MM-DD HH:mm:ss'),
                                        dateTo: moment(event[1]).format('YYYY-MM-DD HH:mm:ss'),
                                    }
                                })
                            }}
                            value={[new Date(state.dateFrom), new Date(state.dateTo)]}
                        />}
                        {isForIE && <RangeDatePicker

                            initialSettings={{ startDate: moment(state.dateFrom).format(pickerFormat), endDate: moment(state.dateTo).format(pickerFormat),
                            locale: {
                                format: pickerFormat,
                                separator: " — ",
                                applyLabel: "Применить",
                                cancelLabel: "Отмена"
                            }}}
                            onApply={(e, picker) => {
                            const dates = e.target.value.split(' — ')
                            setState((prevState) => {
                                return {
                                    ...prevState,
                                    dateFrom: moment(dates[0], pickerFormat).format('YYYY-MM-DD HH:mm:ss'),
                                    dateTo: moment(dates[1], pickerFormat).format('YYYY-MM-DD HH:mm:ss')
                                }
                            })}}>
                                <input type="text" className="form-control" style={{borderColor: '#F57B20'}}/>
                        </RangeDatePicker>}
                    </div>
                    <div
                        onClick={() => {
                            setLocaleStorageItem("stateFromStorage", {
                                timeout: undefined,
                                search: "",
                                dateFrom: moment().add(-1, "y"),
                                dateTo: moment(moment().format("YYYY-MM-DD 23:59:59")),
                                statusesFiltered: [],
                                status: 0,
                                statuses: [...FILTER_OPTIONS.map(elem => ({...elem, isHidden: undefined}))],
                            }, 10);
                            setState({
                                timeout: undefined,
                                search: "",
                                dateFrom: moment().add(-1, "y").format('YYYY-MM-DD HH:mm:ss'),
                                dateTo: moment(moment().format("YYYY-MM-DD 23:59:59")).format('YYYY-MM-DD HH:mm:ss'),
                                statusesFiltered: [],
                                status: 0,
                                statuses: [...FILTER_OPTIONS.map(elem => ({...elem, isHidden: undefined}))],
                            })
                        }}
                        className={styles.clearFilter}
                    >
                        <CloseIcon fontSize="inherit"/>
                        Очистить фильтры
                    </div>
                </div>
                <div className={styles.rightPart}>
                    {checkboxShow && <div
                        className={styles.description}
                    >
                        Отметьте галочками два акта, которые хотите связать между собой:
                    </div>}
                    {checkboxShow && (<Button
                        disabled={docIds.length < 2}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                                handleCreateDocsPair(docIds);
                            }}
                            className={`${styles.buttonAction} ${docIds.length < 2 ? styles.disabled : ""}`}
                        >
                            Сопоставить
                        </Button>)}

                        <Button
                            className={styles.buttonAction}
                            onClick={() => {
                                props.setCheckboxShow(!props.checkboxShow);
                            }}
                        >
                            {props.checkboxShow ? "Отменить" : "Сопоставить вручную"}
                        </Button>

                        <Stack
                            width={'500px'}
                        >
                            <Autocomplete
                                sx={{
                                    backgroundColor: "#FFF !important"
                                }}
                                limitTags={5}
                                autoComplete={false}
                                multiple={false}
                                onChange={(event, value) => {
                                    setState({
                                        ...state,
                                        search: typeof value === 'string' ? value : value.label
                                    })
                                    setLocaleStorageItem("stateFromStorage", {
                                        ...mapStateToStorage(),
                                        search: typeof value === 'string' ? value : value.label
                                    }, 10);
                                    props.getRecognitionActs({
                                        sort: defaultFilter.sort,
                                        sortBy: defaultFilter.sortBy,
                                        extendData: true,
                                        filter: {
                                            status: state.statusesFiltered.map(elem => elem.value),
                                            loadDateFrom: moment(state.dateFrom).toDate().toISOString(),
                                            loadDateTo: moment(state.dateTo).toDate().toISOString(),
                                            jsonContentText: typeof value === 'string' ? value : value.label,
                                        }
                                    }, true)
                                }
                                }
                                filterOptions={(options) => {
                                    return options
                                }}
                                inputValue={state.search}
                                onInputChange={(event, value) => {
                                    if (state.timeout) {
                                        clearTimeout(state.timeout);
                                    }
                                    const timeout = setTimeout(() => {
                                        if (value === "" || (value && value.length >= 3)) {
                                            props.getRecognitionActs({
                                                sort: defaultFilter.sort,
                                                sortBy: defaultFilter.sortBy,
                                                extendData: true,
                                                filter: {
                                                    status: state.statusesFiltered.map(elem => elem.value),
                                                    loadDateFrom: moment(state.dateFrom).toDate().toISOString(),
                                                    loadDateTo: moment(state.dateTo).toDate().toISOString(),
                                                    jsonContentText: value,
                                                }
                                            }, true)
                                        }
                                    }, 600)
                                    setState({
                                        ...state,
                                        search: value,
                                        timeout: timeout,
                                    })
                                    setLocaleStorageItem("stateFromStorage", {
                                        ...mapStateToStorage(), search: value,
                                        timeout: timeout,
                                    }, 10);

                                }}
                                isOptionEqualToValue={(option, value) => {
                                    return option === value;
                                }}
                                freeSolo={true}
                                size={"small"}
                                className={styles.filterItem}
                                disableClearable
                                //@ts-ignore
                                getOptionLabel={(option) => (typeof option === 'string') ? option : `${option.label}`}
                                options={options}
                                renderOption={(props, option) => (<Box
                                    component={"li"}
                                    {...props}
                                    key={option.key}
                                >
                                    {option.label}
                                </Box>)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        size="small"
                                        placeholder={"Поиск по документам"}
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                            endAdornment: (<InputAdornment position={"end"}>
                                                <div className={styles.searchIcon}/>
                                            </InputAdornment>),
                                        }}
                                    />
                                )}
                            />
                        </Stack>
                    </div>
                </div>
                <div className={styles.bottomPart}>
                    {state.statusesFiltered.map(elem => (<div
                        title={elem.label}
                        className={styles.statusElement}
                    >
                        <span> {truncate(elem.label, 10)}</span> <IconButton
                        onClick={() => {
                            const value = elem.value;
                            const findIndex = state.statusesFiltered.findIndex(elem => elem.value === value);
                            const findIndexStatus = state.statuses.findIndex(elem => elem.value === value);
                            const newStatusesFilter = [...state.statusesFiltered];
                            const newStatuses = [...state.statuses];
                            newStatusesFilter.splice(findIndex, 1);
                            newStatuses[findIndexStatus].isHidden = false;

                            setLocaleStorageItem("stateFromStorage", {
                                ...mapStateToStorage,
                                statusesFiltered: [...newStatusesFilter],
                                statuses: [...newStatuses],
                            }, 10);

                            setState((prevState) => ({
                                ...prevState,
                                statusesFiltered: [...newStatusesFilter],
                                statuses: [...newStatuses],
                            }))
                        }}
                        size={"small"}
                    >
                        <CloseIcon fontSize="inherit"/>
                    </IconButton>
                    </div>))}
                </div>
            </div>
            <div ref={tableContainerRef} className={styles.table}>
                <Table {...tableProps} bodyHeight={(tableContainerRef && tableContainerRef.current) ? tableContainerRef.current.clientHeight : 0}/>
            </div>
        </div>
    </PageWrapper>
}
