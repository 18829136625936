import {getEndpoint} from "./endpoints";
import {IDiscrepancyData} from "./monitoringApi";

export interface ICreatePackageProps {
    title: string;
    user: string;
    dbId: string
}

export interface IAddFilesToPackage {
    title: string;
    rawBase64: string;
    packageId: number
}

export interface IPackage {
    title: string;
    user: string;
    packageId: number;
    creationTime: number
}

export interface IAddedFile {
    imageIds: number[]
}

export interface IImagesByPackageId {
    imgId: number[]
}

export interface IConvertedImage {
    rawBase64: string
}

export interface IGetDifferenceProtocolProps {
    organizationName: string;
    periodStart: string;
    periodEnd:string;
    discrepancies: IDiscrepancyData[]
}

class UploadApi {
    async createPackage(props: ICreatePackageProps) {
        return await getEndpoint("/createPackage",)
            .post(props)
            .res();
    }

    async addFileToPackage(props: IAddFilesToPackage) {
        return await getEndpoint("/addFileToPackage",)
            .post(props)
            .res();
    }

    async getImageById(imageId: number) {
        return await getEndpoint(`/images/${imageId}`,)
            .post()
            .res();
    }

    async getImagesByPackageId(packageId: number) {
        return await getEndpoint(`/images/package/${packageId}`,)
            .post()
            .res();
    }

    async sendPackageToRecognition(packageId: number, isPairSearchSkip: boolean) {
        return await getEndpoint(`/recognizeStart/${packageId}`,)
            .post({
                isPairSearchSkip,
            })
            .res();
    }

    async getDifferenceProtocol(id: string) {
        return await getEndpoint(`/doc/${id}/actOfReconciliation/protocol`,)
            .get()
            .res();
    }

    async getCliche(id: string) {
        return await getEndpoint(`/doc/${id}/actOfReconciliation/pdfCliche`,)
            .get()
            .res();
    }

    async sortPackage(packageId: number, ids: number[]) {
        return await getEndpoint(`/sortPackage/${packageId}`,)
            .post({
                imgId: ids,
            })
            .res();
    }
}

export function getUploadApiObj() {
    return new UploadApi();
}
